.modal-body::-webkit-scrollbar {
    margin-top: 10px;
    width: 10px;

}

/* Track */
.modal-body::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 0px 5px 5px 0px;
}

/* Handle */
.modal-body::-webkit-scrollbar-thumb {
    background: #24b14b;
    border-radius: 100px;
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
    background: #24b14b;
}

.card-payment:hover {
    cursor: pointer !important;
}

.search-item-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 10px !important;
    gap: 10px;
}

.item-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px !important;
    gap: 10px;
}

.productRelated .item-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 10px !important;
    gap: 10px;
}

.productRelated .related-itemlist {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 10px !important;
    gap: 10px;
}

.item-list-image {
    transform: unset !important;
    width: 100% !important;
    height: unset !important;
    padding: 5px;
}

.item-list-see-more {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    float: right;
    line-height: 36px;
    color: #24b14b;
}

.card-list-image {
    transform: unset !important;
    width: 80% !important;
    height: unset !important;
    padding: 5px;
}

.item-name {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 2px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
}

.category-title {
    font-size: 23px;
}

.checkOutButton {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
}

.ribbon-wrapper-modal {
    height: 1px;
    left: 0px !important;
    position: absolute;
    top: 60px;
}


// @media only screen and (min-width: 976px) {
//     .card.item-card {
//         .lazy-load-image-background.blur.lazy-load-image-loaded>img {
//             width: 75% !important;
//         }
//     }
// }

.modal-body {
    @media only screen and (min-width: 976px) {
        .card.item-card {
            border: unset;
            border-bottom: solid 1px #dee2e6;
            border-radius: 0px;
        }

        .lazy-load-image-background.blur.lazy-load-image-loaded>img {
            width: 70% !important;
            height: 100% !important;
        }
    }
}

.item-details-col {
    height: 90px;
}

.muted {
    background-color: #c9cdd0;
}

//tablet 
@media (min-width: 676px) and (max-width: 914px) {
    .item-card {
        border-radius: 1.5rem;
        height: fit-content;

    }

    .ribbon {
        top: 20px !important;
        left: 0px;
    }

    .item-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px !important;
        gap: 10px;
    }

    .search-item-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px !important;
        gap: 10px;
    }

    .checkOutButton {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        left: 0 !important;
    }

    .cartDetails {
        width: 60%;
    }

    .modal.cart {
        width: 45% !important;
    }

    .checkout-cart .product-buttons .remove-button {
        width: 80px;
    }

    .lazy-load-image-background.blur.lazy-load-image-loaded>img {
        height: 90px;
        width: 110px;
    }

    .itemModalV2 .lazy-load-image-background.blur.lazy-load-image-loaded>img {
        height: 70% !important;
        width: 70% !important;
    }

    .item-img-col {
        height: 100px;
    }
}

//Mobile Screen
@media only screen and (max-width: 676px) {
    .item-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px !important;
        gap: 10px;
    }

    .search-item-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px !important;
        gap: 10px;
    }

    .ribbon {
        top: 15px !important;
    }

    .item-card {
        overflow: hidden;
        border-radius: 1.5rem;
        height: fit-content;
    }

    .cartDetails {
        width: 100%;
    }

    .productRelated .item-list {
        --visible-cols: 2;
        --col-gap: 20px;
        --col-hint: 0px;
        --scrollbar-padding: 20px;
        --col-size: calc((100% / var(--visible-cols)) - var(--col-gap) - var(--col-hint));
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: var(--col-size) !important;
        grid-auto-columns: var(--col-size) !important;
        overflow-x: auto;
        overflow-y: hidden;
        grid-gap: var(--col-gap);
        padding-bottom: var(--scrollbar-padding);

        // display: grid !important;
        // grid-auto-flow: column !important;
        // grid-auto-columns: 11rem !important;
        // grid-template-columns: unset !important;
        /* do not set template columns and rows */
        grid-template-rows: unset !important;
        // overflow-x: auto !important;
        // overflow-y: hidden !important;
        height: 20rem !important;
        align-items: center !important;
    }

    div#relatedProd1s .container,
    div#saleProds .container {
        padding: 0;
        margin: 0;
    }

    .productRelated .related-itemlist {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 11rem;
        grid-template-columns: unset;
        /* do not set template columns and rows */
        grid-template-rows: unset;
        overflow-x: auto;
        height: 20rem;
        overflow-y: hidden;
        align-items: center;
    }

    .item-list-image {
        padding: 0;
    }

    .item-img-col {
        height: 115px !important;
        overflow: hidden;

        .lazy-load-image-background.blur.lazy-load-image-loaded>img {
            height: auto !important;
            width: 100% !important;
        }
    }

    .item-list::-webkit-scrollbar {
        margin-top: 10px;
        width: 10px;
    }

    /* Track */
    .item-list::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 0px 5px 5px 0px;
    }

    /* Handle */
    .item-list::-webkit-scrollbar-thumb {
        background: #24b14b;
        border-radius: 100px;
    }

    /* Handle on hover */
    .item-list::-webkit-scrollbar-thumb:hover {
        background: #24b14b;
    }

    .item-list::-webkit-scrollbar {
        margin-top: 10px;
        width: 10px;
    }

    /* Track */
    div#relatedProd1s::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 0px 5px 5px 0px;
    }

    /* Handle */
    div#relatedProd1s::-webkit-scrollbar-thumb {
        background: #24b14b;
        border-radius: 100px;
    }

    /* Handle on hover */
    div#relatedProd1s::-webkit-scrollbar-thumb:hover {
        background: #24b14b;
    }

    div#relatedProd1s::-webkit-scrollbar {
        margin-top: 10px;
        width: 10px;
    }
}

.item-description {
    border: none;
    background: transparent;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 1.5;
}

.cursor-disable {
    cursor: not-allowed;
    pointer-events: all !important;
}

//CartButtons
.product-buttons {

    .product-button-oval {
        height: 34.17px;
        width: 100px;
        border: 0px;
        border-radius: 25px;

        &.add-button {
            background: rgb(36, 177, 75);
            color: white;
            font-size: 14px;
            font-weight: bold;
        }

        &.notify-button {
            background: rgb(250, 204, 9);
            font-size: 12px;
            font-weight: bold;
        }

        &.toBeNotify-button {
            background: rgb(250, 204, 9);
            font-size: 10px;
            color: #fff;
            background-color: #6c757d;
            cursor: not-allowed;
            pointer-events: all !important;
        }

        &.remove-button {
            background: #dc3545;
            color: white;
            font-size: 14px;
            font-weight: bold;
        }

        //Mobile Screen
        @media only screen and (max-width: 676px) {
            // width: 80%;
            text-align: center;
        }
    }

    .product-button-round {
        height: 33px;
        width: 33px;
        border-radius: 25px;
        font-weight: bold;
        font-size: 14px;

        &.increment-button {
            border: 0;
            background: rgb(36, 177, 75);
            color: white;
            border: 2px solid rgb(36, 177, 75);
            display: inline-block;
            font-weight: 400;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            line-height: 1.5;
            padding: 0 !important;
            margin: 0 !important;
        }

        &.decrement-button {
            border: 0;
            background: #fff;
            color: white;
            border: 2px solid rgb(36, 177, 75);
            display: inline-block;
            font-weight: 400;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            line-height: 1.5;
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    .product-input {
        height: 33px;
        width: 33px;
        border-radius: 0.25rem;
        text-align: center;
        background: transparent;
        margin: 0px 3px;
        display: inline;
        border: 0;
        border-bottom: 1px solid #dee2e6 !important;
    }
}

//modal
.itemModalV2 {
    @media only screen and (max-width: 676px) {
        .lazy-load-image-background.blur.lazy-load-image-loaded>img {
            height: auto !important;
            width: 100% !important;
        }
    }

    .float-right .product-buttons {
        float: right;
    }

    .card.item-card.false {
        background-color: transparent;
        border-radius: unset;
    }

    .card.item-card.true {
        border-radius: unset !important;
    }
}

//modal
.checkout-cart {
    @media (min-width: 676px) and (max-width: 914px) {

        .lazy-load-image-background.blur.lazy-load-image-loaded>img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    @media only screen and (min-width: 976px) {
        .card.item-cart {
            border: unset;
            border-bottom: solid 1px #dee2e6;
            border-radius: 0px;
        }

        .item-list-image-modal.lazy-load-image-background.blur.lazy-load-image-loaded>img {
            width: 90% !important;
            height: 100% !important;
        }
    }

    .card.item-card.false {
        background-color: transparent;
        border-radius: unset;
    }

    .card.item-card.true {
        border-radius: unset !important;
    }

    .row.h-100 {
        background-color: transparent;
    }

    .product-buttons {
        @media only screen and (min-width: 677px) {
            position: unset;
            bottom: 0;
            right: 0;
            width: 105px;
        }

        .product-button-round {
            height: 27px;
            width: 27px;
            border-radius: 25px;
            font-weight: bold;
            font-size: 14px;

            &.increment-button {
                border: 0;
                background: transparent;
            }

            &.decrement-button {
                border: 0;
                background: transparent;
            }
        }

        .remove-button {
            background: #dc3545;
            color: white;
            font-size: 12px;
            font-weight: bold;
        }

    }
}

.item-remove {
    background: rgba(0, 0, 0, 0.1);
}


/////// SPECIAL PRODUCT CSS
/// 
/// 
/////// SPECIAL PRODUCT CSS
/// 
/// 
/// 

// global

.pv-h1 {
    font-size: 20px !important;
}

.txt-algnC {
    text-align: center;
}

.lh-30 {
    line-height: 30px;
}

.bdr {
    border: 1px solid rgba(0, 0, 0, .125);
}

.bdr-btm {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.dsktp-w {
    width: 130vh;
}

.auto-mv {
    margin: 0 auto;
}

.auto-mh {
    margin: auto 0;
}

.auto-m {
    margin: auto;
}

.dsktp-m {
    width: 1300px;
    margin: auto;
}

.mtb-1rem {
    margin: 1rem 0;
}

.r5-mv {
    margin: 1rem auto;
}

.mt2r {
    margin-top: 2rem;
}

.mt1r {
    margin-top: 1rem;
}

.hdrh1 {
    font-size: 30px;
}

.br-5 {
    border-radius: 5px;
}

.flx-hc {
    display: flex !important;
    align-content: center;
    align-items: center;
}

// 

.productView {
    // margin: 0 25vh;
    padding: 0 4%;

    // border-radius: 0 !important;
    p,
    span,
    ul,
    .listT {
        font-size: 20px;
    }

    //CSS for stars
    $star-size: 40px;
    $star-on-color: #F9DF4A;
    $star-off-color: #CCCCCC;

    .rate {
        height: $star-size;
        display: block;

        .star {
            position: relative;
            width: $star-size;
            height: $star-size;

            &:before {
                content: "\2605"; // display star
                font-size: $star-size;
                line-height: 1;
            }
        }

        .starOn {
            color: $star-on-color;
        }

        .starOff {
            color: $star-off-color;
        }

    }

    .productSelected {
        .hoverDiv {
            img {
                height: auto;
                width: auto;
            }
        }

        .prodViewDesc {

            .paymentIconsDiv {

                // display: flex;
                // align-items: center;
                // justify-content: center;
                // padding: 10px;
                // background-color: lightgray;
                // border-radius: 10px;
                .paymentIcons {
                    width: 60%;
                    height: auto;
                    padding: 10px;
                    background-color: lightgray;
                    border-radius: 10px;
                }
            }

            .prodName,
            .price h1,
            .hookline h1 {
                font-size: 30px !important;
            }

            .price {
                font-size: calc(1.175rem + 1.5vw);

            }

            h1 {
                margin: 0;
            }


            .prodDesc {
                span {
                    margin: 1rem 0 !important;
                    line-height: 25px;
                }
            }

            .payments {
                margin: 1rem 0;

                p {
                    padding: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    .fbShare,
    .copybtn {
        height: 27.17px !important;
        cursor: pointer;
    }

    button {
        height: 34.17px !important;
    }

    .fbShare img {
        height: 100% !important;
    }

    .copybtn {
        width: 50px;
        justify-content: center;

        img {
            height: 24px !important;
        }
    }

    .fbShare {
        width: 30px;

        img {
            margin: auto;
            display: block;
        }
    }

    .prodViewBtns {
        // margin-top: 1rem;
        align-items: center;

        .product-buttons {
            width: 115px;
        }


    }

    .productSpecial {
        .relatedProdIngr {
            margin-top: 1rem;
        }

        .prodBranches {

            h1,
            p {
                font-size: 20px;
            }

            .prodViewIcon {
                width: 25px;
                height: 25px;
            }
        }

        .prodBullets {
            img {
                width: 90% !important;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }

        .prodViewFiller {
            .contentView {
                margin-bottom: 50px;

                img {
                    width: 100%;

                }
            }
        }

        .prodReviews {
            margin-top: 1rem;
            $img-size : 190px;

            .prodReviewHdr {
                .filter {
                    justify-content: flex-end;
                    align-content: center;
                    align-items: center;
                    justify-content: flex-end;

                    p {
                        padding: 0;
                        margin: 0;
                    }
                }
            }

            .content {
                .contentCommentImgWrapper {
                    height: $img-size;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;

                    img {
                        width: $img-size;
                    }
                }

                .commentSection {
                    padding: 1rem 0;

                    .rateDate {
                        float: right;

                        p {
                            color: gray;
                            margin: 0;
                        }
                    }

                    span {
                        margin-bottom: 10px;
                        color: gray;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 660px) {
    .div#relatedProdIngr .item-list {
        --visible-cols: 2;
        --col-gap: 20px;
        --col-hint: 0px;
        --col-size: calc((100% / var(--visible-cols)) - var(--col-gap) - var(--col-hint));
        grid-auto-columns: var(--col-size) !important;
    }

    .prodDesc,
    .mt2r {
        margin: 0;
    }

    .prodBullets {
        padding-top: 0 !important;
        margin: 0 !important;
    }

    .prodViewDesc {
        margin-bottom: 0 !important;
        margin-top: 1rem !important;
    }

    .relatedProdIngr,
    .prodReviews {
        margin-top: 0 !important;
        margin-bottom: 1rem !important;
    }

    .prodRelated {
        margin-bottom: 2rem !important;

        .item-list {
            height: 18rem !important;
        }
    }

    .productView {

        .prodName,
        .price h1,
        .hookline h1,
        .hdrh1 {
            font-size: 30px !important;
        }

        p,
        span,
        ul,
        .listT,
        h6 {
            font-size: 16px !important;
        }

        .prodImg,
        .prodInfo,
        .paymentIcons {
            width: 100% !important;
        }

        .productSpecial {

            .prodBenefits,
            .prodSpecs {
                min-width: 300px;
                width: 100%;
            }

            .prodBranches {

                h1,
                p {
                    font-size: 16px !important;
                }
            }
        }
    }
}


//image slider

.thumbnail-slider-wrap {
    height: 100%;
    padding: 5px 0;
}

.thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto !important;
    margin: 0 2px;

}

.thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 100%;
}

// .slick-slide.slick-current {
//     filter: unset !important;
//     background-color: transparent !important;
//     opacity: unset !important;
// }

// .slick-slide.slick-active {
//     filter: brightness(90%);
//     opacity: 0.6;
// }

// .slick-slide {
//     filter: brightness(90%);
//     opacity: 0.6;
// }


.HoverDiv {
    margin: 0;
}

.slider-wrapper {
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.slick-slide {
    text-align: start;
    position: relative;
}

.slick-slide:focus {
    outline: unset;
}

.slick-slide-image {
    max-width: 62px;
    height: auto !important;
    border-radius: 10px;
}

.slick-slide-image-main {
    max-width: 80% !important;
    min-height: auto !important;
    height: auto !important;
    border-radius: 8px;
}

.slick-slide-main {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.slick-prev:before,
.slick-next:before {
    color: #777777 !important;
}

.thumbnail {
    border-radius: 10px;
}


// sub-category
div.sub-category .col-4.d-none.d-sm-block.my-auto.search_pc.pt-1 {
    visibility: hidden;
}

div.sub-category .item-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));

}

div.sub-category {
    .slick-slide-image-main {
        max-width: 100% !important;
        min-height: 350px !important;
        height: auto !important;
        border-radius: 8px;
    }
    .col-4.d-sm-block.my-auto.search_pc.pt-1 {
        visibility: hidden;
    } 
    @media (min-width: 676px) and (max-width: 914px) {
        .slick-slide-image-main {
            max-width: 50% !important;
            min-height: 233px !important;
            height: auto !important;
            border-radius: 8px;
        }
    }

    @media only screen and (max-width: 676px) {
        .slick-slide-image-main {
            max-width: 100% !important;
            min-height: 216px !important;
            height: auto !important;
            border-radius: 8px;
        }
    }

}

div#productPage {
    .slick-slide-image-main {
        max-width: 100% !important;
        min-height: 350px !important;
        height: auto !important;
        border-radius: 8px;
    }

    @media (min-width: 676px) and (max-width: 914px) {
        .slick-slide-image-main {
            max-width: 100% !important;
            min-height: 233px !important;
            height: auto !important;
            border-radius: 8px;
        }
    }

    @media only screen and (max-width: 676px) {
        .slick-slide-image-main {
            max-width: 100% !important;
            min-height: 216px !important;
            height: auto!important;
            border-radius: 8px;
        }
    }


}

//sublisting modal

div.sub-listing-modal {

    .slick-slide-image-main {
        max-width: 100% !important;
        min-height: 210px !important;
        height: auto !important;
        border-radius: 8px;
    }

    .main-image {
        padding: unset !important;
    }

    .col-slick-slider {
        padding: 2px !important;
    }

    .slick-slide-image {
        max-width: 50px;
        height: auto !important;
        border-radius: 10px;
    }

}

//sublisting

.sublisting-name-btn:hover {
    background-color: #24b14b;

    .sublisting-name {
        color: #fff !important;
    }
}

.sublisting-name:hover {
    color: #fff !important;
}

div#relatedProd-sublisting {
    .itemlist-container{
        padding: 0px;
    }
    .category-title{
        margin-top: 25px !important;
    }
}