
.productsCheck{
  overflow-y: auto;
  overflow-x: hidden;
}
.cart::-webkit-scrollbar {
  margin-top: 10px;
  width: 10px;

}
/* Track */
.cart::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 0px 5px 5px 0px;
}

/* Handle */
.cart::-webkit-scrollbar-thumb {
  background: #24b14b;
  border-radius: 100px;
}

/* Handle on hover */
.cart::-webkit-scrollbar-thumb:hover {
  background: #24b14b;
}

.card-payment:hover {
  cursor: pointer !important;
}

@media only screen and (max-width: 600px) {
  /* #checkOutModal .row.justify-content-md-center.m-0.pc_nav {
    display: none;
  } */

  #checkOutModal .col.p-0 {
    display: inline-flex;
    overflow-y: auto;
  }

  #checkOutModal .card.rounded {
    width: 240px;
    min-height: 133px;
  }

  #checkOutModal .shadow-sm.p-3.mb-2.bg-white.rounded.timeCard {
    width: 47%;
    padding: 2% 2% !important;
    display: inline-flex;
    margin: 0 1.5%;
    font-size: 16px;
    text-align: center !important;
  }

  #checkOutModal .row.mb-5.pb-3.mt-2 {
    margin-bottom: 0 !important;
  }

  #checkOutModal .paymentMethod .col-6 {
    width: 100% !important;
  }

  #checkOutModal .shadow-sm.p-3.mb-2.bg-white.rounded.timeCard small {
    width: 100%;
  }

  #checkOutModal .paymentMethod .card-body {
    padding: 0 !important;
  }

  #checkOutModal .hideonModal {
    display: none;
  }

  /* div#checkOutModal {
    margin-bottom: 16.5vh;
  } */
  .table-details {
    margin-bottom: 10rem;
    padding: unset;
  }

  .fade.modal.show {
    padding: 0 !important;
  }

  div#checkOutModal .paymentMethod {
    margin-right: 0% !important;
    border: 1px solid #a0a6ab;
    border-radius: 8px;
    height: auto !important;
  }

  #checkOutModal .container.mt-2.mb-5.pb-2 {
    margin-bottom: 1rem !important;
  }

  textarea.form-control.w-100 {
    height: 110px;
  }

  #table>tbody>tr:nth-child(odd)>td {
    background-color: white
  }

  .table>tbody>tr>td {
    font-size: .9rem;
    padding: 1rem !important;
  }
}


/* paymentLISt */
.paymentContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 10px !important;
  gap: 10px;
}
.paymentDesktop{
  cursor: pointer;
}

@media (min-width: 676px) and (max-width: 914px) {
  .paymentContainer {
    display: inline-flex;
    overflow: scroll;
    padding: 10px;
  }
  .paymentDesktop{
    min-width: 50%;
  }
  .paymentContainer::-webkit-scrollbar {
    margin-top: 10px;
    width: 10px;
  
  }
  /* Track */
  .paymentContainer::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 0px 5px 5px 0px;
  }
  
  /* Handle */
  .paymentContainer::-webkit-scrollbar-thumb {
    background: #24b14b;
    border-radius: 100px;
  }
  
  /* Handle on hover */
  .paymentContainer::-webkit-scrollbar-thumb:hover {
    background: #24b14b;
  }
  
}