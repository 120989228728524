//Delivery Form
input#Del_date {
  text-align: center;
}

.mt10 {
  margin-top: 14rem;
}

.mt5 {
  margin-top: 5rem !important;
}

.w30 {
  width: 30% !important;
}

#shopIn button {
  background-color: transparent !important;
  color: black;
  text-align: center;
  border: 0px solid !important;
  margin-left: 1%;
  border-radius: 8px;
}

#shopIn .dropdown-menu.show {
  width: 100%;
  text-align: center;
}

//End delivery form

//sidebar
.sidenav_show {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  height: 1000px;
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  margin-top: 0%;
  text-align: left;
}

.sidenav_hide {
  -webkit-transition: all 1.5s ease;
  -moz-transition: all 1.5s ease;
  -o-transition: all 1.5s ease;
  -ms-transition: all 1.5s ease;
  transition: all 1.5s ease;
  width: 0;
  position: fixed;
  z-index: 0;
  left: -1000px;
  background-color: #111;
  overflow-x: hidden;
  padding: 0px;
  height: 1000px;
  top: 0;
  margin-top: 0%;

}

.btnSet {
  width: 10000px;
  height: 700px;
  background: transparent;
  opacity: 0.5;
  z-index: 2;
  position: absolute;
}

.btnAuto {
  width: 0;
  height: 0;
}

//search
//ItemCard
.item_titleCart {
  height: unset !important;
}

.borderRadiusGrid {
  border-radius: 1.5rem !important;
}

.eye.active {
  color: #24b14b !important;
}

//ItemCard
//desktop
@media only screen and (min-width: 976px) {

  .accordion div#feedbackHistory .rating {
    width: 34% !important;
    margin: 0 !important;
    height: 30px !important;
  }

  .mt10 {
    margin-top: 25% !important;
  }


  .btn_cityy button {
    float: right;
    padding: 0.5% 3% !important;
    border: 1px solid #ced4da;
  }

  // .col-1.pb-2.text-center.my-auto.cart_logg a {
  //     display: inline !important;
  // }
  .dropdown-toggle::after {
    display: none;
  }

  #categoryNav {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    padding: 5px;
    height: 40px;
  }

  #categoryNav .list {
    position: absolute;
    top: 0px;
    min-width: 157%;
    margin-left: 12px;
    margin-top: 0px;
  }

  a.nav-link.pb-0 {
    padding: 0;
    padding-top: 10px;
  }

  #categoryNav .list li {
    display: table-cell;
    position: relative;
    text-align: center;
    color: #efefef;
    vertical-align: middle;
  }

  .scroller {
    text-align: center;
    cursor: pointer;
    display: none;
    padding: 3px;
    padding-top: 5px;
    white-space: no-wrap;
    vertical-align: middle;
    background: #f4cc0f;
  }

  .scroller-right {
    position: absolute;
    right: 0;
    bottom: 4px;
    display: block;
    z-index: 2;
    width: 5%;
  }

  .scroller-left {
    position: absolute;
    left: 0;
    bottom: 4px;
    display: block;
    z-index: 2;
    width: 5%;
  }

  #categoryNav .actived {
    border-bottom: 2px solid #222;
    font-weight: bold;
  }

  #categoryNav .actived a {
    font-family: Poppins-Regular;
    font-weight: bold !important;
  }

  #categoryNav li.nav-item {
    width: 1%;
  }

  .footer_ {
    margin-bottom: 0 !important;
  }

  .modal-dialog {
    margin: 0.5rem auto !important;
  }

  .cartDetails {
    width: 100% !important;
    display: block !important;
    border-radius: 50px !important;
  }

  .cart_logg {
    padding: 8px 0 !important;
  }

  .userDropdown button#dropdown-basic {
    padding-top: 3px;
  }

  .dropdown-item {
    display: block !important;
  }

  h2 {
    font-size: 30px !important;
  }

  #thankYou_con {
    height: 100% !important;
    padding-bottom: 10%;
  }

  #thankYou_con img {
    width: 25%;
  }


}

.accordion-body .ratings {
  width: 38%;
}

//mobile view spacing adjustments
@media only screen and (max-width: 640px) {
  .cart_logg {
    display: none;
  }

  .ribbon {
    font: bold 15px Sans-Serif !important;
    padding: 3px 0 !important;
  }

  .ribbon-wrapper {
    top: 0px !important;
    left: -1px !important;
  }

  .categBodyPage div#slideProd {
    overflow-x: unset;
    display: inline-flex;
    flex-wrap: wrap !important;
  }

  .categBodyPage #slideProd .col-6.p-1.mb-2 {
    width: 50% !important;
  }

  .col.p-1 .lazy-load-image-background.blur.lazy-load-image-loaded,
  .col.p-1 .lazy-load-image-background.blur.lazy-load-image-loaded>img {
    min-height: auto !important;
  }

  .col.p-1 .ribbon {
    padding: 0 !important;
    left: 13px;
    top: 13px;
  }

  .searchForm.p-4.pt-1.pb-1 input.form-control:focus {
    z-index: 1;
  }

  button#btn_clear {
    top: 13px !important;
  }

  #modal-content .ribbon {
    left: 0px !important;
    top: 15px !important;
  }

  #modal-content .ribbonNew {
    left: 4px;
    top: 35px;
    width: 65px !important;
    font-size: 15px !important;
    text-align: left !important;
  }

  #modal-content .ribbonSeasonal {
    left: 9px !important;
    top: 35px;
    width: 105px !important;
    font-size: 14px !important;
    text-align: left !important;
  }

  .accordion div#feedbackHistory .rating {
    width: 47% !important;
    margin: 0 !important;
    height: 30px !important;
  }

  .mt10 {
    margin-top: 28rem !important;
  }

  .categBodyPage {
    margin-top: 16rem !important;
  }

  .modal-content #feedBackBody .rating {
    width: 78% !important;
  }

  .rating {
    width: 45% !important;
  }

  .accordion-body .ratings {
    width: 62% !important;
  }

  .searchForm.p-4.pt-1.pb-1 span#basic-addon1 {
    border-radius: 0 !important;
    height: 34px !important;
    background-color: #f6f6f6 !important;
    border: 0px;

  }

  .searchForm.p-4.pt-1.pb-1 input.form-control {
    border-radius: 0 !important;
    height: 34px !important;
    background-color: #f6f6f6 !important;
    border: 0px;
  }

  .searchForm.p-4.pt-1.pb-1 {
    padding: 0.5rem !important;
    background: #fff !important;
  }

  .productsCheck h5.prodItem_.mb-1 {
    font-size: 0.8rem !important;
  }

  button#sortby_field {
    padding: 4px 4px;
    font-size: 13px;
    margin-left: 5%;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .row.justify-content-md-center.m-0.pc_nav {
    height: 36px !important;
  }

  #productPage .row.justify-content-md-center.m-0.pc_nav {
    height: 60px !important;
  }

  #Birthdate_field span.valid,
  #Birthdate_field span.invalid {
    position: absolute;
    left: 90px;
    top: 8%;
  }

  #Email_field span.valid,
  #Email_field span.invalid {
    position: absolute;
    left: 60px;
    top: 8%;
  }

  #Phone_field span.valid,
  #Phone_field span.invalid {
    color: #24b14b !important;
    position: absolute;
    left: 62px;
    top: 8%;
  }

  span.valid {
    color: #24b14b !important;
    position: absolute;
    left: 100px;
    top: 8%;
  }

  span.invalid {
    position: absolute;
    left: 100px;
    top: 8%;
  }

  #CPassword_field span.valid,
  #CPassword_field span.invalid {
    color: #24b14b !important;
    position: absolute;
    left: 160px;
    top: 8%;
  }

  .mobileCategg_ {
    top: -100px !important;
  }

  .cartdetails_ .card-body {
    width: 100% !important;
  }

  .pt-1.pb-1.text-center.dropCategory {
    width: 85%;
  }

  .itempriceSale {
    // width: 25%;
    display: inline-flex;
  }

  .text-secondary {
    display: inline-flex;
    width: 50%;
    float: right;
    line-height: 16px;
    font-weight: 300;
    font-size: 14px !important;
    margin: 0 !important;
    margin-top: 2px !important;
  }

  #slideProd p.mb-2.text-secondary {
    line-height: 23px;
  }

  #cartShowed p.mb-2.text-secondary,
  #itemModal p.mb-2.text-secondary {
    display: block !important;
    width: 100% !important;
  }

  #cartShowed p.mb-2.text-secondary:before {
    content: "  ";
    margin: 0px;
  }

  .itempriceSale {
    display: inline-flex;
    width: 100%;
    float: left;
  }

  .itempriceSale span {
    text-align: right;
    display: block;
    float: left;
    font-size: 14px;
    width: auto;
  }

  p.mb-2.text-secondary:before {
    content: " / ";
    margin: 0 3px;
  }

  .row.customMargin {
    margin-top: 2.3rem;
  }


}

@media only screen and (max-width: 640px) {
  button.btn.pt-0.pb-0 {
    width: 100%;
    text-align: left !important;
    font-size: 15px !important;
  }

  .col-6.pt-1.pb-1.btn_cityy {
    width: 100% !important;
    padding: 0% !important;
  }

  .pc_nav .col-3.p-0.d-block.d-sm-none {
    position: absolute;
  }

  #mobileLogo {
    width: 75% !important;
    // padding: 5% !important;
    // padding-top: 2% !important;
  }

  .col-6.pt-1.pb-1.btn_cityy {
    width: 50% !important;
  }

  button.btn.pt-0.pb-0 {
    text-align: right !important;
  }

  .w-100.m-0.p-0.bg-light.wholeHeader .row.justify-content-md-center.m-0.pc_nav {
    height: 55px !important;
  }

  .col-6.col-md-4.pt-1.pb-1.btn_cityy.my-auto>h1 {
    margin-top: 1% !important;
  }

  button#cityField {
    margin-top: 1%;
    color: rgb(36, 177, 75) !important;
  }

  button#cityField span {
    color: #545353 !important;
  }

  button#cityField svg {
    stroke: #55b24d !important;
  }

  // h2.mb-3.mt-3.categTitle_ {
  //   margin-bottom: 3rem !important;
  // }

  a.nav-link img {
    height: 20px !important;
    width: 20px !important;
  }

  img#sideBar {
    vertical-align: baseline;
  }

  .col.userInfo {
    padding: 0.5rem 1rem;
  }

  nav.navbar.navbar-expand.navbar-light {
    padding-bottom: 0 !important;
  }

  .bg-light.sidenav_show img {
    width: 20% !important;
    height: 100% !important;
  }

  #salePriceSpan {
    font-size: 14px !important;
    line-height: 27px;
  }

  span#strikePrice {
    font-size: 8px !important;
    line-height: 30px;
    width: auto !important;
  }

}

//order history
.orderedProducts {
  transition: opacity 1s ease-out 0s;
}


// links description

.description_Icons {
  font-size: 12px;
  /* font-weight: bold!important; */
  height: 50px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


.iconLink {
  text-align: right !important;

}

@media only screen and (max-width: 676) {
  .othersRow {
    height: 50px !important;
  }

  .col-6.iconLink.m-auto img {
    height: 45px;
    width: 45px;
  }
}

//checkout button
.btn-primary {
  background-color: #24b14b !important;
  color: #fff;
  border: #24b14b !important;
}

.dateCard {
  cursor: pointer;
}

//footer
footer {
  position: relative;
  z-index: 2;
}


button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

button:focus {
  box-shadow: unset !important;
}

//newsletterCSS
div#Newsletter_Field span.valid {
  display: none !important;
  ;
}

div#Newsletter_Field label {
  font-size: 13px;
  cursor: pointer;
}


//ipad view

div#Newsletter_Field span.valid {
  display: none;
}

div#Newsletter_Field label {
  font-size: 13px;
  cursor: pointer;
}


li.nav-item a {
  text-decoration: none;
  // vertical-align: -webkit-baseline-middle;
  display: block;
}

// ipad pro start
@media only screen and (min-width: 801px) and (max-width: 1099px) {
  #firstContent #categoryNav .list {
    top: 37% !important;
  }

  #categoryNav .list {
    top: 6%;
  }

  .prod_ROW {
    margin-bottom: 5%;
  }

  .mt10 {
    // margin-top: 20% !important;
    margin-bottom: 50%;
  }

  .itemDetails {
    flex: 0 0 auto;
    width: 100% !important;
  }

  .col-sm-4.mb-2 img.img-fluid.p-1 {
    transform: scale(1.8);
    padding-left: 15% !important;
    margin: 13% 0;
  }
}

//ipad styles
// @media only screen and (min-width: 760px) and (max-width: 800px) {
//   .itemDetails {
//     flex: 0 0 auto;
//     width: 100%;
//   }

//   .col-sm-4.mb-2 img.img-fluid.p-1 {
//     transform: scale(2.0);
//     padding-left: 15% !important;
//     margin: 13% 0;
//   }

//   .productsCheck .col {
//     padding: 0 !important;
//     font-size: 12px !important;
//   }

//   div#categoryNav {
//     display: none !important;
//   }

//   .col-2.d-none.d-sm-block.text-center {
//     padding: 0;
//   }

//   button#cityField {
//     width: 100%;
//   }

//   .col-1.text-center.my-auto.cart_logg {
//     width: 16.666% !important;
//   }

//   .productsCheck .bg-transparent {
//     padding: 8px !important;
//   }

//   div#cartdetCheckout {
//     padding-left: 0;
//     padding-right: 5 px;
//   }

//   div#cartdetCheckout div#productzgnRow {
//     display: block !important;
//   }

//   .container,
//   .container-md,
//   .container-sm {
//     max-width: 100% !important;
//     top: 14rem !important;

//   }

//   #categoryNav .actived a {
//     font-family: Poppins-Regular;
//     font-weight: bold !important;
//     text-decoration: underline;
//   }

//   #categoryNav {
//     position: relative;
//     margin: 0 auto;
//     overflow: hidden;
//     padding: 5px;
//     height: 40px;
//   }

//   #CityModal .showModal,
//   .modal.login.showModal {
//     top: -1500px !important;
//   }

//   .pc_nav .col-1.d-none.d-sm-block.text-center {
//     margin: auto 0;
//   }

//   .pc_nav .col-3.d-none.d-sm-block.my-auto.search_pc.pt-1 {
//     width: 40%;
//   }

//   .pc_nav .col-7.pt-1.pb-1.btn_cityy {
//     width: 37%;
//     margin: auto 0;
//     font-size: 14px;
//   }

//   .scroller-left,
//   .scroller-right {
//     position: absolute;
//     bottom: 4px;
//     display: block;
//     z-index: 2;
//     width: 5%;
//   }

//   #categoryNav {
//     position: relative;
//     margin: 0 auto;
//     overflow: hidden;
//     padding: 5px;
//     height: 40px;
//   }

//   #categoryNav .list {
//     font-size: 13px;
//     position: absolute;
//     top: 0px;
//     min-width: 158%;
//     margin-left: 0;
//     margin-top: 3px;
//   }

//   #categoryNav .list li {
//     display: table-cell;
//     position: relative;
//     text-align: center;
//     color: #efefef;
//     vertical-align: middle;
//   }

//   .scroller {
//     text-align: center;
//     cursor: pointer;
//     display: none;
//     padding: 3px;
//     padding-top: 5px;
//     white-space: no-wrap;
//     vertical-align: middle;
//     background-color: transparent;
//   }

//   .scroller-right {
//     position: absolute;
//     right: 0;
//     bottom: 4px;
//     display: block;
//     z-index: 2;
//     width: 5%;
//   }

//   .scroller-left {
//     position: absolute;
//     left: 0;
//     bottom: 4px;
//     display: block;
//     z-index: 2;
//     width: 5%;
//   }

//   .secondSlide {
//     left: -71%;
//     -webkit-transition: all 0.3s ease;
//     -moz-transition: all 0.3s ease;
//     -o-transition: all 0.3s ease;
//     -ms-transition: all 0.3s ease;
//     transition: all 0.3s ease;
//   }

//   .firstSlide {
//     left: 0%;
//     -webkit-transition: all 0.3s ease;
//     -moz-transition: all 0.3s ease;
//     -o-transition: all 0.3s ease;
//     -ms-transition: all 0.3s ease;
//     transition: all 0.3s ease;
//   }

//   .row.footer_ {
//     margin-bottom: 0 !important;
//   }

//   div#noanim-tab-example-tabpane-register {
//     overflow: auto;
//     height: 300px;
//   }

//   .modal-dialog {
//     max-width: 500px;
//     margin: 1.75rem auto !important;
//   }

//   .itemDetails h5.mb-1 {
//     font-size: 13px !important;
//     height: 46px !important;
//   }

//   button.d-none.d-sm-inline-block.btn.btn-transparent {
//     display: inline-block;
//     width: 10px;
//     margin-right: 15%;
//   }

//   .col-7.pt-1.pb-1.btn_cityy button {
//     font-size: 12px;
//   }

//   .col-1.pb-2.mt-0.text-center.my-auto.cart_logg {
//     width: 14%;
//   }

//   .cart_logg div {
//     width: auto;
//     position: absolute !important;
//     top: 6px !important;
//     right: 8px !important;
//   }

//   .categoryTag p {
//     top: -60px !important;
//   }

//   .row.justify-content-md-center.m-0.pc_nav {
//     padding-top: 0.5%;
//   }

//   .cart_logg svg {
//     stroke: #222;
//   }

//   #categoryNav {
//     height: 30px;
//   }

//   .scroller-right,
//   .scroller-left {
//     bottom: 0px;
//   }

//   #categoryNav {
//     height: 30px;
//   }

//   .scroller-right,
//   .scroller-left {
//     bottom: 0px;

//     .pc_nav .col-7.pt-1.pb-1.btn_cityy {
//       width: 37%;
//       margin: auto 0;
//       font-size: 14px;
//     }

//     .cart_logg div {
//       width: 27px;
//       position: absolute !important;
//       top: 6px !important;
//       right: 18px !important;
//     }
//   }

//   div#cartCount {
//     top: 4px !important;
//     right: 50px !important;
//   }

//   h5.mb-1.item_titleCart {
//     font-size: 13px !important;
//     height: 30px !important;
//   }

//   .modal.cart {
//     width: 38% !important;
//   }

//   .scroller.scroller-right {
//     display: none;
//   }
// }

//sidebar categories
@media only screen and (min-width: 976px) {
  .mt10 {
    width: 80% !important;
    float: right;
  }

  ul.nav.nav-pills.nav-fill.list.firstSlide>form {
    display: block !important;
    padding: 0%;
    text-align: left !important;
  }

  ul.nav.nav-pills.nav-fill.list.firstSlide>form.categ-d-none {
    display: none !important;
  }

  #categoryNav .list {
    position: fixed !important;
    top: 14%;
    display: block !important;
    background: #fff;
    min-width: 10%;
    z-index: -1 !important;
    display: block !important;
    width: 17%;
    padding: 0%;
    white-space: nowrap;
  }

  #categoryNav li.nav-item {
    display: block !important;
    width: 100%;
    text-align: left;
    font-size: 14px;
    padding: 1% 5%;
    padding: 0% !important;
  }

  ul.nav.nav-pills.nav-fill.list {
    left: 0 !important;
  }

  ul.nav.nav-pills.nav-fill.list.secondSlide_last {
    top: -10% !important;
  }

  .scroller {
    display: none !important;
    height: 0 !important;
  }

  div#categoryNav {
    height: 0 !important;
    padding: 0 !important;
  }

  .container.product_list {
    padding: 0 0%;
  }

  #firstContent #categoryNav .list {
    top: 75%;
  }


  #categoryNav .actived {
    border-bottom: 0px solid #f1b815;
    background: #f1b815;
    font-weight: bold;
  }

  .container.product_list:last-child {
    margin-bottom: 15%;
  }
}

select#citySelected {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) white no-repeat 98.5% !important;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) white no-repeat calc(100% - 10px) !important;
}

.prod_ROW {
  --bs-gutter-x: 0.5rem !important;
  width: 100%;
  top: 0;
  position: relative;
}

#slideProd .col-sm-4.mb-2 {
  margin-right: 0%;
}

@media only screen and (max-width: 676px) {
  div#slideProd h5.prodItem_.mb-1 {
    font-size: 13px !important;
    height: 38px !important;
  }

  #slideProd .col-sm-4.mb-2 {
    margin-right: 1%;
  }

  #productPage .ADDLABEL {
    width: 100% !important;
    height: 26px !important;
    font-size: 10px !important;
  }

}

#categoryNav li.nav-item {
  display: block !important;
  width: 100%;
  text-align: left;
  font-size: 17px;
  padding: 1% 5%;
  padding: 0% !important;
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1920px) {
  #firstContent #categoryNav .list {
    top: 88% !important;
  }
}

@media screen and (min-device-width: 1501px) and (max-device-width: 1920px) {
  #categoryNav li.nav-item {
    font-size: 22px;
  }
}


@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min +floor($rand * (($max - $min) + 1));

  @return $randomNum;
}


// #relatedProds #prodCard {
//   display: none !important;
// }

$total: 5;
// randomNum(1, 20)

.related-d-None {
  display: none !important
}

@for $i from 1 through $total {
  $random: random(12);

  #relatedProds #prodCard:nth-child(#{$random}) {
    display: inline-block !important;
    // color:red !important;
  }
}



div#productPage .col-4.d-none.d-sm-block.my-auto.search_pc.pt-1 {
  visibility: hidden;
}

.productsCheck div#productzgnRow {
  display: block;
}

#productPage .productSelected .btnMinus,
#productPage .productSelected .btnAdd {
  width: 25px !important;
  height: 25px !important;
  stroke: #fff !important;
}

#productPage .productSelected .btnMinus svg,
#productPage .productSelected .btnAdd svg {
  stroke: #fff !important;
}

.CopyLink {
  display: inline-block;
}

.copylinkbtn:active {
  background: rgb(216, 177, 22) !important;
  opacity: 0.75;
}

.copylinkbtn {
  background: rgb(250, 204, 2);
  border-radius: 4px;
  font-size: 13px;
  height: 29px;
  padding-top: 29px !important;
  padding: 2px 10px !important;
  vertical-align: bottom;
}


.productsCheck img,
.productsCheck .col-sm-6.p-0.itemDetails,
.productsCheck h5.prodItem_.mb-1,
.productsCheck .col {
  cursor: default !important;
}

//CSS for stars
$star-size: 55px;
$star-on-color: #F9DF4A;
$star-off-color: #CCCCCC;

.rating {
  width: 62%;
  height: $star-size;
  display: block;
  margin: 0 auto;

  label {
    float: right;
    position: relative;
    width: $star-size;
    height: $star-size;
    cursor: pointer;

    &:before {
      content: "\2605"; // display star
      font-size: $star-size;
      color: $star-off-color;
      line-height: 1;
    }
  }

  input {
    display: none;
  }

  input:checked~label:before,
  // pag nacheck na yung radio, css to para mag stay ung color ng star
  &:not(:checked)>label:hover:before,
  // hover para sa start na gusto mong iselect
  &:not(:checked)>label:hover~label:before // hover para sa mga naunang start sknya na di pa din selected

    {
    color: $star-on-color;
  }
}



//forms sa loob ng orderhistory
.ratings {
  width: 36%;
  height: 30px;

  label {
    float: right;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:before {
      content: "\2605"; // display star
      font-size: 30px;
      color: $star-off-color;
      line-height: 1;
    }
  }

}



.align-items-center {
  align-items: center !important;
  z-index: 1062 !important;
}

#listofProds {
  list-style-type: unset !important;
}

#listofProds>tbody>tr:nth-of-type(1n+4) {
  display: none;
}

.productListFB {
  display: revert !important;
}

#showProds,
#showLess {
  cursor: pointer;
  font-size: 12px;
}

#showLess {
  display: none;
}

.text-orange {
  color: #FF7F50 !important;
  text-align: center;
}

.text-greenlight {
  color: #24b14b !important;
  text-align: center;
}

.text-yellow {
  color: #f4cc0e !important;
  text-align: center;
}

.bgYellow::before {
  color: #f4cc0e !important;
  text-align: center;
}

input.searchInput_.form-control {
  z-index: 2 !important;
}

#rateText,
#ratingLabel {
  text-align: center;
  font-size: 20px;
}

li.productList.list,
.ordernumTitle,
#listofProds li {
  font-size: 13px !important;
  padding: 0px 6px !important;
}


.ribbon-wrapper {
  width: 108px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  right: -6px;
}

.ribbon {
  font: bold 13px Sans-Serif;
  line-height: 18px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  position: relative;
  padding: 2px 0;
  left: -4px;
  top: 31px;
  z-index: 1;
  width: 50px;
  background-color: #dc3545;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
  background-image: radial-gradient(circle farthest-side, #dc3545, #dc3545);
  box-shadow: -3px 5px 6px -5px rgba(0, 0, 0, 0.5);
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0% 100%, 0% 100%, 0% 0%);
}

.ribbon:before,
.ribbon:after {
  content: "";
  border-top: 4px solid #dc3545;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  bottom: -4px;
}

.ribbon:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 4px solid #dc3545;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-top: 4px solid #dc3545;
}

.ribbon:after {
  content: "";
  position: absolute;
  right: 7px;
  top: 100%;
  z-index: -1;
  border-left: 4px solid transparent;
  border-right: 4px solid #dc3545;
  border-bottom: 4px solid transparent;
  border-top: 4px solid #dc3545;
}

.ribbon-wrapper {
  width: 108px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  left: 0;
}

.productsCheck .priceDetails strong {
  float: left !important;
}

.productsCheck p.mb-2.text-secondary {
  display: none;
}

#modal-content .ribbon {
  // left: 6px;
  width: 70px;
  font-size: 20px;
  line-height: 24px;

}


div#feedbackHistory .rating {
  width: 54% !important;
  margin: 0 !important;
  height: 30px !important;

}

div#feedbackHistory .rating label:before {
  font-size: 30px;
}

div#feedbackHistory .rating label {
  float: right;
  position: relative;
  width: 30px !important;
  height: 55px;
  cursor: pointer;
}

div#feedbackHistory b#rateText,
div#feedbackHistory .col-12.text-center {
  text-align: left !important;
  font-size: 16px !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #111;
  background-color: #facc02;
}

div#modal-content .ribbon-wrapper {
  width: 108px !important;
  height: 108px !important;
  overflow: hidden;
  position: absolute;
  top: -6px;
  right: -5px;
}

.ribbon {
  left: 0;
  top: 10px;
  z-index: 1;
}

.ribbonNew {
  background: #f1b815;
  width: 55px !important;
  text-align: left;
  font-size: 13px !important;
}

#modal-content .ribbonNew {
  width: 70px !important;
  font-size: 16px !important;
}

.ribbonSeasonal {
  background: #f1b815;
  width: 79px !important;
  text-align: left;
  font-size: 11px !important;
}

#modal-content .ribbonSeasonal {
  width: 80px !important;
  font-size: 11px !important;
  text-align: left !important;
}

.productsCheck .ribbon-wrapper {
  right: 17px;
}

#itemModal p.mb-2.text-secondary:before {
  content: "  ";
  margin: 0px;
}

#listofProds>tbody>tr>td {
  padding: 2px 5px !important;
  border-style: unset !important;
}

#listofProds>:not(:last-child)>:last-child>* {
  border-bottom-color: #cdc7c7 !important;
}

.misc-h {
  // z-index: 12;
  width: 100%;

  .d-h {
    background-color: white;
    position: fixed;
    top: 0;
    border-bottom: 1px solid lightgray;
  }

  img {
    max-width: 10%;
    position: absolute;
    float: left;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5%;
    padding: 0 !important;
  }

  .userDropdown {
    margin-right: 13%;
  }

  .userIcon {
    margin-right: 13%;
  }

  .d-h,
  .m-h {
    z-index: 20 !important;
  }
}

.misc-bm {
  margin-top: 5%;
  margin-left: 20% !important;
  margin-right: 20% !important;

  .misc-blog-front {
    position: sticky;
    display: flex;
    z-index: 20;
    top: 66px;
    border: 1px solid lightgray;
    padding: 15px;
    background-color: white;
    border-radius: 5px;

    .blogHome {
      text-decoration: none;
      color: #000;
    }

    h1 {
      font-size: 4vw;
      line-height: 1.2;
      margin-bottom: 0;
    }

    .blog-blg {
      margin-top: auto;
      margin-bottom: auto;

      p {

        margin-bottom: 0 !important;
        padding-top: 0 !important;
      }

      span {
        color: #24b14b;
        line-height: 18px;
      }

      .blogBtn {
        color: black;
        text-decoration: none;
      }

      .blogBtn:hover {
        color: #24b14b !important;
      }
    }

    .blogQuotes a {
      font-size: 13px;
      margin-bottom: auto;
      // margin-right: 30px;
      margin-top: auto;
    }
  }

  .slideBlogHead {
    z-index: 5;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    a {
      float: right;
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      text-decoration: none;
      color: #24b14b;
    }

    a:hover {
      text-decoration: underline;
    }

    h4 {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  .slideBlogContainer {
    padding: 10px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, .125);
    margin-top: 20px;
    border-radius: 5px;
  }

  .slideBlog {
    padding-bottom: 10px;
    margin-top: 5px;
    display: flex;
    width: 100%;
    // background-color: white;
    // padding: 10px;
    overflow: auto;

    .slideCard {
      margin-left: 10px;
      margin-right: 10px;
    }

    .card {
      height: 100%;
    }

    .imgContainer {
      overflow: hidden;
      max-height: 200px;
    }

    .card:hover {
      .card-img-top {
        transform: scale(1.3);
        transition: transform 1s;
      }
    }

    .card-img,
    .card-img-top {
      height: 100%;
      overflow: hidden;
    }

    .card-title a {
      height: 85px;
      color: #000;
      font-size: 1rem;
      margin-bottom: 0;
      text-decoration: none;
    }

    .card-title a:hover {
      color: #24b14b;
      text-decoration: underline;
    }
  }


  // template css for blog card

  // end
}


.blogCard {
  $color_white: #fff;
  $color_prime: #5ad67d;
  $color_grey: #e2e2e2;
  $color_grey_dark: #a2a2a2;
  $zagana-color: #24b14b;

  * {
    box-sizing: border-box;
  }

  body {
    background: #f1f1f1;
    margin: 2rem;
  }

  .blog-card {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(#000, .1);
    margin-bottom: 1.6%;
    background: $color_white;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;

    a {
      color: inherit;

      &:hover {
        color: $color_prime;
      }
    }

    &:hover {
      .photo {
        transform: scale(1.3) rotate(3deg);
      }
    }

    .meta {
      position: relative;
      z-index: 0;
      height: 200px;
    }

    .photo {
      margin-left: -15px;
      margin-right: -15px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -150px;
      background-size: cover;
      background-position: center;
      transition: transform 1s;
    }

    .details,
    .details ul {
      margin: auto;
      padding: 0;
      list-style: none;
    }

    .details {
      margin-left: -13px !important;
      margin-right: -13px !important;
      position: absolute;
      width: 106%;
      top: 0;
      bottom: 0;
      right: -110%;
      margin: auto;
      transition: right .2s;
      background: rgba(#000, .6);
      color: $color_white;
      padding: 10px 10px 10px 30px;
      font-size: .9rem;

      a {
        text-decoration: dotted underline
      }

      ul li {
        display: inline-block;
      }

      .author:before {
        font-family: FontAwesome;
        margin-right: 10px;
        content: "\f007";
      }

      .date:before {
        font-family: FontAwesome;
        margin-right: 10px;
        content: "\f133";
      }

      .tags {
        ul:before {
          font-family: FontAwesome;
          content: "\f02b";
          margin-right: 10px;
        }

        li {
          margin-right: 2px;

          &:first-child {
            margin-left: -4px;
          }
        }
      }
    }

    .description {
      padding: 1rem;
      background: $color_white;
      position: relative;
      z-index: 1;

      .card-btn {
        float: right;
        color: #24b14b;
        font-size: .9rem;
        text-decoration: none;
      }

      .card-btn:hover {
        text-decoration: underline;
      }

      .description-content {
        max-height: 160px;
        overflow: hidden;
        margin-bottom: 10px;

      }

      h1 {
        line-height: 1;
        margin: 0;
        font-size: 1.7rem;

        a {
          text-decoration: none;
        }
      }

      h2 {
        font-size: 1rem;
        font-weight: 300;
        text-transform: uppercase;
        color: $color_grey_dark;
        margin-top: 5px;
      }

      .read-more {
        text-align: right;

        a {
          color: $color_prime;
          display: inline-block;
          position: relative;

          &:after {
            content: "\f061";
            font-family: FontAwesome;
            margin-left: -10px;
            opacity: 0;
            vertical-align: middle;
            transition: margin .3s, opacity .3s;
          }

          &:hover:after {
            margin-left: 5px;
            opacity: 1;
          }
        }
      }
    }

    p {
      position: relative;
      margin: 1rem 0 0;

      &:first-of-type {
        margin-top: 1.25rem;

        &:before {
          content: "";
          position: absolute;
          height: 5px;
          background: $color_prime;
          width: 35px;
          top: -0.75rem;
          border-radius: 3px;
        }
      }
    }

    &:hover {
      .details {
        right: 0%;
      }
    }

    @media (min-width: 640px) {
      flex-direction: row;

      .meta {
        flex-basis: 40%;
        height: auto;
      }

      .description {
        flex-basis: 60%;

        &:before {
          transform: skewX(-3deg);
          content: "";
          background: #fff;
          width: 80px;
          position: absolute;
          left: -40px;
          top: 0;
          bottom: -1px;
          z-index: -1;
        }

        .content {
          overflow: hidden;
        }
      }

      &.alt {
        flex-direction: row-reverse;

        .description {
          &:before {
            left: inherit;
            right: -10px;
            transform: skew(3deg)
          }
        }

        .details {
          padding-left: 25px;
        }
      }
    }
  }

  .blogs-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    grid-gap: 10px;
    padding: 10px;
    justify-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;

    .card {
      overflow: hidden;

      span.lazy-load-image-background.blur.lazy-load-image-loaded {
        overflow: hidden;
      }
    }

    .card:hover {
      transform: scale();

      .lazy-load-image-background.blur.lazy-load-image-loaded>img {
        transform: scale(1.5);
        transition: transform 1s;
      }
    }

    .content {
      word-wrap: break-word;
      overflow: hidden;

      .text {
        height: 96px;
      }
    }

    .card-btn {
      text-decoration: none;
      position: absolute;
      bottom: 10px;
      right: 15px;
      color: $zagana-color;
      font-size: .9rem;
    }

    .card-title a {
      font-size: 1rem;
      text-decoration: none;
      color: #000;
    }

    .card-title a:hover {
      text-decoration: underline;
      color: $zagana-color;
    }
  }
}

.yt {
  border-radius: 5px;
  background: white;
}

.yt-blogs {
  box-shadow: 0px 2px 10px -6px;
}

.blogContent {
  .topicContainer {
    background-color: white;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 40px;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 5px;
    margin-top: 10px;
    // margin-left: 10%;
    // margin-right: 10%;

    // p{
    //   line-height: 30px;
    //   margin-bottom: 2rem;
    // }
    .datePublished {
      font-style: oblique;
    }

    pre {
      white-space: pre-wrap;
      font-family: Poppins-Regular;
      // font-family: monaco,consolas,"courier new",monospace;
      font-size: 1em;
      min-height: 3em;
      overflow: auto;
      padding: 1em;
      width: 80%;
      background-color: red;
    }

    img {
      border-radius: 5px;
      width: 100%;
      margin-bottom: 25px;
    }

    .mainTopic,
    .subContentBody {

      // .refArticle{
      //   color: gray;
      //   font-style: oblique;
      //   font-size: 13px;
      //   p{
      //     word-wrap: break-word;
      //   }
      // }
      h1 {
        text-align: center;
      }

      .topicHeader {
        font-size: 30px;
        margin-bottom: 30px
      }
    }

    .mainTopic {
      margin-top: -25px;

      .mainTopicTitle {
        margin-top: 20px;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 50px;
        font-weight: bold;
      }

      img {
        margin-bottom: 45px;
      }
    }

    .subTopic {
      justify-items: center;
      margin-bottom: 2rem;
      margin-top: 2rem;

      .subContent {
        margin-left: 5%;
        margin-right: 5%;

        .subContentHeader {
          font-size: 1.2rem;
          // color: #24b14b;
        }
      }
    }

    .topicText {
      white-space: break-spaces;
      margin-bottom: 40px;
    }

    .subTopicHeader,
    .mainTopicHeader {
      color: #24b14b;
    }
  }
}

.headerLine {
  background-color: #24b14b;
  height: 6px;
  width: 60px;
  margin-bottom: 30px;
  border-radius: 5px;
}

//delivery time desktop

@media only screen and (max-width: 1024px) {
  .misc-bm {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .misc-blog-front {
    p {
      font-size: 2rem;
      padding-top: 4% !important;
    }
  }
}

@media only screen and (min-width: 976px) {

  .lazy-load-image-background.blur>img {
    height: auto;
  }

  .topicContainer {

    .lazy-load-image-background.blur.lazy-load-image-loaded,
    .lazy-load-image-background.blur.lazy-load-image-loaded>img {
      // margin-left: auto;
      // margin-right: auto;
      // display: block;
      width: 100% !important;
    }
  }

  .HoverDiv {
    position: relative;
    overflow: hidden;
    margin: 10px;
  }

  .HoverDiv img {
    max-width: 100%;
    text-align: center;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 450px;
    margin: 0 auto;
    mix-blend-mode: multiply;

    display: block;
  }

  .HoverDiv:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.2);
  }

  div#prodCard {
    display: inline-block;
    width: 24%;
    margin-right: 1.2%;
  }

  #productPage .container.product_list {
    min-height: auto;
    margin: 0;
  }

  div#productzgnRow {
    overflow: hidden;
    display: flex;
  }

  #productPage h5.prodItem_.mb-1 {
    cursor: pointer !important;
  }

  #productPage div#addMinus {
    width: 105px !important;
  }

  .misc-bm {
    margin-left: 0;
    margin-right: 0;
  }

  .misc-blog-front {
    p {
      font-size: 2rem;
      line-height: 45px;
    }

    h1 {
      font-size: 4vw;
    }

    h5 {
      font-size: 2vw;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: inherit !important;
  }

  .timeCard:hover {
    font-weight: bold;
  }

  .activeDate,
  .timeUnderline {
    color: #000;
    background: #fff !important;
    border-bottom: 4px solid #24b14b !important;
  }

  button#timeFormDesktop {
    background-color: white !important;
    width: 100%;
    color: black;
    text-align: left;
    border-radius: 8px;
  }

  #timeSched .dropdown-menu.show {
    width: 100%;
  }

  .shadow-sm.p-3.mb-2.bg-white.rounded.timeCard {
    width: 23% !important;
    display: inline-block;
    margin-right: 14px;
    text-align: center;
    cursor: pointer;
  }
}

@media only screen and (max-width: 914px) {
  .topicContainer {
    .lazy-load-image-background.blur.lazy-load-image-loaded>img {
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
      display: block;
      width: 90% !important;
      height: auto;
    }
  }
}

@media only screen and (max-width: 676px) {
  .mainTopic {
    margin-top: -25px;
  }
}

@media only screen and (max-width: 640px) {
  div#relatedProds #prodCard {
    margin-bottom: 0.5rem !important;
    margin-top: 0rem !important;
  }

  #CO_Page .col-6.col-md-4.pt-1.pb-1.btn_cityy.my-auto {
    display: none;
  }

  .row.justify-content-md-center.m-0.pc_nav {
    height: 50px !important;
  }

  .fieldcartQty {
    width: 25% !important;
  }

  div#relatedProds {
    height: auto;
    min-height: auto;
    overflow: hidden;
  }

  .paymentMethod {
    width: auto !important;
  }

  .misc-bm {
    margin-top: 10% !important;
  }

  .sticky {
    .blog-blg p {
      font-size: 1rem;
    }
  }

  .misc-blog-front {
    top: 59px !important;

    p {
      font-size: 2rem;
      padding-top: 4% !important;
    }

    span {
      font-size: 20px;
    }
  }

  .blogCard .blog-card .photo {
    margin-right: -65px !important;
  }

  .blogContent .topicContainer {
    padding: 15px !important;
  }

  .topicContainer {
    p {
      font-size: 14px !important;
    }

    .mainTopicTitle {
      font-size: 24px !important;
      margin-bottom: 25px !important;
    }

    .topicHeader {
      font-size: 26px;
    }

    .mainTopicHeader,
    .subTopicHeader {
      font-size: 22px;
    }

    .subContentHeader {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .flashTimer>span {
    font-size: 12px !important;
    padding: 0 !important;
  }

  #mobileLogo {
    max-height: 100%;
    max-width: 55%;
    position: absolute;
    padding-left: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .m-logo {
    position: relative;
  }

  button#cityField {
    padding-right: 10px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 500px) {
  .mainTopic {
    .lazy-load-image-background.blur.lazy-load-image-loaded>img {
      margin-top: 35px;
    }
  }

  .blogQuotesBtn {
    margin-right: 0 !important;
    font-size: 15px;
  }

  .misc-blog-front span {
    font-size: 15px;
  }
}

@media only screen and (max-width: 550px) {

  .blogCard .blogs-card {
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));

    .Allblogs {
      width: 100% !important;

      .card-title {
        line-height: 15px;

        a {
          font-size: 12px;
        }
      }
    }
  }

  .ytDesc,
  .featuredBlogP {
    font-size: 12px !important;
  }

  .blogContent .topicContainer p {
    font-size: 13px !important;
    line-height: 25px !important;
  }

  .m-p10 {
    padding: 5px;
  }

  .m-cm10 {
    margin: 7px !important;
  }

  .m-br {
    border-radius: 5px !important;
  }

  .w-100.m-0.p-0.bg-light.wholeHeader .row.justify-content-md-center.m-0.pc_nav {
    height: 60px !important;
  }

  .m-csf .carousel {
    margin-right: 10px !important;
    margin-left: 10px !important;
    border-radius: 7px !important;
  }

  .m-csf .carousel .carousel-inner {
    background-color: white;
  }

  .m-csf .carousel .carousel-item {
    border-radius: 7px !important;
    background-color: white;
  }

  .m-csf .carousel-control-prev {
    margin-top: 0;
  }

  .m-csf .carousel-control-next {
    margin-top: 0;
  }

  .m-csf .carousel .carousel-item img {
    border-radius: 5px !important;
    background-color: white;
  }

  #mobileLogo {
    max-width: 63% !important;
  }

  h2.mb-3.mt-3.categTitle_ {
    margin-top: 0rem !important;
    margin-bottom: 0.5rem !important;
  }

  #h_sale .categTitle_ {
    margin-top: 35px !important;
  }

  div#slideProd {
    margin-bottom: 0.5rem !important;
    flex-wrap: unset;
  }

  .blogContent .topicContainer .subTopic {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr)) !important;
  }

  .misc-bm {
    padding-top: 5% !important;

    .misc-blog-front {
      padding: 10px;
    }

    .topicHeader {
      font-size: 22px !important;
    }

    .mainTopicHeader,
    .subTopicHeader {
      font-size: 20px !important;
    }

    .blogContent .topicContainer {
      .mainTopic .mainTopicTitle {
        margin-top: 25px !important;
        font-size: 1rem !important;
        margin-bottom: 0 !important;
      }

      .topicText {
        margin-bottom: 0;
      }

      .subTopic {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    .headerLine {
      margin-bottom: 0;
    }

    .blogContent .topicContainer p {
      font-size: 13px !important;
      line-height: 25px !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  .blog-blg {
    line-height: 18px !important;
  }

  div#relatedProds #prodCard {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  .modal-content #feedBackBody .rating {
    width: 85% !important;
  }

  .misc-blog-front p {
    font-size: 1rem !important;
  }

  .blogCard .blogs-card {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  }
}

@media only screen and (max-width: 380px) {
  .modal-content #feedBackBody .rating {
    width: 89% !important;
  }

  #mobileLogo {
    width: 70% !important;
  }

  .w-100.m-0.p-0.bg-light.wholeHeader .row.justify-content-md-center.m-0.pc_nav {
    height: 62px !important;
  }

  button#cityField {
    font-size: 12px !important;
  }

  #mobileLogo {
    max-width: 65% !important;
  }
}

@media only screen and (max-width: 360px) {
  .modal-content #feedBackBody .rating {
    width: 92% !important;
  }

  .m-loc {
    margin-left: -22px !important;
  }

  #mobileLogo {
    width: 100% !important;
    max-width: 75% !important;
  }

  button#cityField {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 326px) {
  .m-loc {
    margin-left: -34px !important;
  }
}


.progress {
  width: 150px;
  height: 150px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #000;
  font-size: 24px;
  color: #fff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}

.progress.blue .progress-bar {
  border-color: #049dff;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
  border-color: #fdba04;
}

.progress.yellow .progress-right .progress-bar {
  animation: loading-3 1.8s linear forwards;
}

.progress.yellow .progress-left .progress-bar {
  animation: none;
}

.timerStyle {
  position: absolute;
  bottom: 3px;
  left: 0;
  max-width: 100%;
}

.borderBlack {
  border: 3px solid #222;
  border-bottom: 0;
}

.borderwithBottom {
  border: 3px solid #222;
}

.cart-count {
  position: absolute;
  top: 3px;
  right: 10%;
  width: 25px;
  height: 25px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #24b14b;
  color: #fff;
}