/* Delcaring Fonts */
@font-face {
    font-family: "Poppins-Regular";
    src: local("Poppins-Regular"),
        url("../src/assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins-Black";
    src: local("Poppins-Black"),
        url("../src/assets/fonts/Poppins-Black.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins-Italic";
    src: local("Poppins-Italic"),
        url("../src/assets/fonts/Poppins-Italic.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins-Thin";
    src: local("Poppins-Thin"),
        url("../src/assets/fonts/Poppins-Thin.ttf") format("truetype");
}


$font-stack: Poppins-Regular;
$black-color: #222;
$white-color: #fff;
$primary-color: #24b14b;
$secondary-color: #facc02;
$disabled-color: #6c757d;
$error-color: #ff1e02;
$primary-size: calc(1.175rem + 1.5vw);
$secondary-size:calc(1.095rem + 1.5vw);
$tertiary-size: calc(1.075rem + 1.5vw);

body {
    font: 100% $font-stack;
    color: $black-color;
    background-color: #f5f5f8;
}

.alert-secondary {
    background-color: $secondary-color;
}

button.btn.btn-primary,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: $primary-color !important;
    color: $white-color;
    border: $primary-color;
}

h1 {
    font-size: $primary-size;
}

h2 {

    font-size: $secondary-size;
}

h3 {

    font-size: $tertiary-size;
}

/*forms and error/success span: (Start)*/
// span.error {
//     position: absolute;
// }

// for register and login forms
span.error, .error {
    color: $error-color;
}

span.invalid {
    color: $error-color !important;
    position: absolute;
}

span.valid {
    color: $primary-color !important;
    position: absolute;
}

.valid input {
    border: 1px solid $primary-color !important;
}

span.valid {
    color: $primary-color !important;
}

div#Gender_field .valid {
    left: 78px;
    top: 5%;
}

/*forms and error/success span: (End)*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.filterDark{
    filter: brightness(0.5)
}

h5#flash::after,h5#Flash::after {
    content: ' (Max of 2 items or until stocks last)';
    font-size: 12px!important;
    display: inline;
    padding-left: 1%;
}
//Mobile Screen
@media only screen and (max-width: 676px) {
    .prodSlider{
        --visible-cols: 2;
        --col-gap: 20px;
        --col-hint: 0px;
        --scrollbar-padding: 20px;
        --col-size: calc((100% / var(--visible-cols)) - var(--col-gap) - var(--col-hint));
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: var(--col-size) !important;
        grid-auto-columns:var(--col-size) !important;
        overflow-x: auto;
        overflow-y: hidden;
        grid-gap: var(--col-gap);
        padding-bottom: var(--scrollbar-padding);
    }
    .categBodyPage .prodSlider{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        grid-gap: 10px !important;
        gap: 10px !important;
        grid-auto-flow: unset !important;
    }
    .flashInfo{
        display: inline-flex;
        width: 100%!important;
        font-size: 11px!important;
    }
    h5#flash::after,h5#Flash::after  {
        content: '(Max of 2 items or until stocks last)';
        font-size: 10px!important;
        display: block;
    }
    img.filterDark {
        width: 100%;
        margin-top: 30%;
        margin-bottom: 36%;
    }
    .bodyTime > small{
        display:block !important;
        width: 100px !important;
        color:#dc3545;
        font-size: 12px;
        font-weight: bold;
    }
    .timerStyle {
        bottom: -10px !important;
    }
    .form-group {
        margin-bottom: 2%;
    }
    #listofProds>tbody>tr>td{
        padding: 2px 12px !important;
        border-style: unset !important;
      }
      
    .productsCheck {
        margin-bottom: 1.5rem;
    }
    .productsCheck .btnAdd svg {
        stroke: #fff !important;
    }
    .lazy-load-image-background.blur.lazy-load-image-loaded, .lazy-load-image-background.blur.lazy-load-image-loaded > img {
        mix-blend-mode: multiply;
      }
    span.lazy-load-image-background.blur.lazy-load-image-loaded {
        transform: scale(1.1);
    }
    #h_sale .categTitle_ {
        margin-top: 3rem !important;
      }
    .col-9.p-0.itemDetails {
        padding: 0% !important;
    }
    .col-6.pt-1.pb-1.btn_cityy {
        text-align: center !important;
        padding: 4px 0px 0% 0 !important;
        // width: 100%;
    }

    .col-6.pt-1.pb-1.btn_cityy button {
        text-align: center;
        padding: 0% !important;
        font-size: 12px ;
        margin: 0 auto;
        width: 100%!important;
    }

    button.btn.btn-primary-outline {
        font-size: 13px;
    }

    button#setID {
        padding: 2% !important;
    }

    // .col-7.pt-1.pb-1.btn_cityy h5{
    //     margin-top: 8% !important;
    // }
    h1 {
        font-size: calc(0.85rem + 1.5vw) !important;
    }

    .prodItem_ {
        // display: block !important;
        font-size: 0.70rem !important;
    }

    h5.prodItem_.mb-1 {
        padding: 0% 0.5%;
        padding-top: 5%;
        text-align: left !important;
        height: 34px !important;
    }
    .container.product_list h5.mb-1 {
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .col-6.p-1.mb-2 .lazyload-wrapper {
        min-height: 92px;
    }

    #slideProd .btnAdd {
        width: 20px !important;
        height: 20px !important;
        font-size: 10px !important;
    }

    .product_list .card-body {
        padding: 0% !important;
    }

    #slideProd .col-6 img.img-fluid.p-1 {
        width: 50%;
        margin: 0 auto;
        display: block;
        transform: scale(1.5);
        padding-top: 6% !important;
    }

    #slideProd .btnAdd,
    #slideProd .btnMinus {
        width: 20px !important;
        height: 20px !important;
        font-size: 10px !important;
    }

    #slideProd .buttonsField input {
        margin: 0 !important;
        padding: 0 !important;
    }


    #slideProd .col-12.p-2.itemDetails {
        padding: 0 !important;
        position: relative;
    }

    #slideProd .w-100.buttonsField {
        min-height: 30px;
        margin-top: 35px !important;
        margin-bottom: 0.5rem !important;
    }
    #slideProd .addSale {
        width: 100% !important;
        /* height: 100px !important; */
        height: 25px !important;
        font-size: 10px !important;    
    }
    #slideProd .notifyBtn {
        width: 100% !important;
        /* height: 100px !important; */
        height: 25px !important;
        font-size: 10px !important;    
    }
    .product_list .row {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0 !important;
        margin: 0;
    }
    .col-sm-4.p-1.mb-2 .col-12.p-2.itemDetails {
        position: relative;
    }
    .col-sm-4.mb-2 img {
        transform: scale(1.2);
        padding-left: 9% !important;
    }
    #slideProd .col-sm-4.mb-2 img {
        transform: scale(1) !important;
        padding-left: 9%!important;
    }
    div#slideProd h5.prodItem_.mb-1 {
        font-size: 10px !important;
    }
    #slideProd .col-sm-4.mb-2 {
        margin-right: 1%;
        width: 75%;
        padding: 1%;
    }

    #slideProd .col-sm-4.mb-2 div#addMinus {
        width: 62px !important;
        float: right;
        padding-right: 0rem !important;
    }
    .col-sm-4.mb-2 .itemDetails {
        position: relative;
        padding-left: 1% !important;
    }
    .userInfo a.nav-link {
        text-align: center;
        padding: 0.5rem 1rem;
        padding-top: 3px;
    }
    #productPage .container.product_list {
        padding: 0 !important;
        min-height: auto;
    }
    #productPage div#prodCard {
        display: inline-block;
    }
    #productPage .categoryTag {
        display: none;
    }
    #productPage h2{
        margin-top:20px;
    }
}

// General for whole site
.overflow-auto.cart.bg-light.rounded-bottom.row.productsCheck h5 {
    height: auto !important;
}
.col-6.p-1.mb-2 .col-12.p-2.itemDetails {
    position: relative;
}
.col-6.p-1.mb-2 .borderRadiusGrid {
    padding-top: 8% !important;
}

.col-6.p-1.mb-2 img.img-fluid.p-1 {
    transform: scale(1.4);
}

#slideProd .col-6.p-1.mb-2 {
    width: 45% !important;
}
.col-sm-4.mb-2 .itemDetails {
    position: relative;
    padding-left: 5% !important;
}

.col-sm-4.mb-2  img.img-fluid.p-1 {
    transform: scale(1.5);
    padding-left: 15% !important;
}

div#slideProd {
    display: -webkit-inline-box;
    overflow-x: scroll;
    padding: 0;
}

#slideProd .col-sm-4.mb-2 {
    margin-right: 1%;
}

#slideProd .card-body.p-1.pb-1 {
    padding: 0 !important;
}
/* width */
    ::-webkit-scrollbar {
        height: 5px;
        width: 10px;
    }

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.w-100.buttonsField {
    min-height: 35px;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .3) !important;
}

button.btn.btn-success {
    width: 100%;
}

button:disabled,
.btn-primary.disabled,
.btn-primary:disabled {
    color: $white-color;
    background-color: $disabled-color;
    border-color: $disabled-color;
}

.btn-outline-success {
    color: $black-color;
    border-color: $primary-color !important;
}

.btn-outline-success:active,
.btn-outline-success:hover {
    color: $white-color;
    background-color: $primary-color !important;
}

input.form-control {
    padding: 1% 2% !important;
}

.container.product_list {
    min-height: 550px;
}
svg.bi.bi-eye.eye {
    opacity: 0.5;
    opacity: 0.5;
}
.pc_nav .btn_cityy button {
    padding-left: 2%!important;
    padding: 5px !important;
    width: 60%;
    text-align: center;
}

input.searchInput_::placeholder {
    color: #888;
    font-size: 14px;
}

button#setID {
    padding: 1% 3% !important;
    padding-left: 3% !important;
    width: auto;
    text-align: center;
}

button.btn.pt-0.pb-0.shadow-sm svg {
    stroke: #ff1e02;
    width: 10px;
    vertical-align: middle!important;
}
a.dropdown-item {
    white-space: inherit;
}
div#cartdetCheckout svg {
    stroke: rgb(36, 177, 75);
}
.disabledCOD{
    background: #a0a6ab;
    pointer-events: none;
    cursor: not-allowed !important;
    color:#fff;
}
.disabledCOD img{
    filter: invert(100%);
}
.disabledCOD .row-6.my-auto {
    position: absolute;
    bottom: 0;
    right: 0px;
    color: #f8f9fa;
}
pre.mb-2.text-secondary {
    font-family: Poppins-Regular;
}
// End General for wholesite
//all ccs for desktop view
@media only screen and (min-width: 976px) {
    // body{
    //     height: random(300) + px !important;
    // }
    .container {
        width: 100%;
        max-width: 100%;
    }
    .container.product_list {
        padding: 0 5%;
    }
    .productsCheck span.lazy-load-image-background.blur.lazy-load-image-loaded {
        transform: scale(1);
    }
    .lazy-load-image-background.blur.lazy-load-image-loaded > img {
        width: 100% !important;
        height: 100% !important;
      }
    .pc_nav {
        background-color: #fff !important;
        padding: 0.3% 0 !important;
    }

    .col-3.d-none.d-sm-block.search_pc {
        width: 35% !important;
    }

    .col-6::after.pb-1.btn_cityy {
        margin: auto 0;
    }

    .container.product_list h5 {
        font-size: 17px !important;
    }
    .container.product_list .premiumProds h5 {
        font-size: 20px !important;
        margin-top:5% !important;
        margin-bottom:3% !important;
    }
    .secondSlide {
        left: 0%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .firstSlide {
        left: 0%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .pc_nav {
        background-color: #fff !important;
        padding: 0.3% 0 !important;
        padding-bottom: 0.5% !important;
    }

    span.invalid,
    span.valid {
        top: 0 !important;
        right: -5px !important;
    }

    .d-flex.justify-content-center.mb-5.no_prods,
    .load_prods {
        padding: 15% 10%;
    }

    .container.product_list h5.mb-1 {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 44px !important;
    }
}

.d-flex.justify-content-center.mb-5.no_prods,
.load_prods {
    padding: 10% 10%;
}

.activeDate {
    color: #fff;
    background: #24b14b !important;
}

.dateCard {
    text-align: center;
}

#cartdetCheckout .bg-transparent {
    position: relative;
}

#firstContent .carousel-inner {
    margin: 0 !important;
}

//start -ipad, mini view
@media screen and (min-device-width: 500px) and (max-device-width: 1000px) { 
    .container, .container-md, .container-sm {
        max-width: 100% !important;
    }
    .mt10{
        margin-top: 24rem!important;
      }
    .prod_ROW{
      margin: 0 !important;
    }
    .container, .container-md, .container-sm {
      max-width: 100%;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide {
        top: 80vh;
        overflow-x: scroll;
        display: flow-root;
        padding: 0px !important;
        white-space: nowrap;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide > form {
        display: inline-block;
        text-align: center;
        padding: 14px;
        text-decoration: none;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide > div,.scroller {
        display: none !important;
    }
    .pc_nav .btn_cityy button {
        padding: 5px !important;
        width: 100%;
        text-align: center;
    }
   .pc_nav .col-1.text-center.my-auto.cart_logg {
        width: 16% !important;
    } 
    svg.bi.bi-cart2 {
        stroke: #222;
        width: 18px;
    }
    .userDropdown svg {
        stroke: #222;
        width: 18px;
    }
    .col-6.col-md-4.pt-1.pb-1.btn_cityy.my-auto {
        width: 20% !important;
        font-size: 12px  !important;
        padding: 0px !important;
    }
    .col-4.d-none.d-sm-block.my-auto.search_pc.pt-1 {
        width: 38% !important;
    }
    button#cityField{
      font-size:12px !important;
    }
    .col-2.d-none.d-sm-block.text-center {
        width: 25% !important;
    }
    h5.prodItem_.mb-1 {
        height: 40px !important;
    }
    div#cartCount {
        top: 9px !important;
        right: -4px !important;
    }
    div#itemModal .modal-dialog {
        max-width: 100%;
    }
  }

//end - ipad, mini view
@media (min-width: 768px) {

    .container, .container-md, .container-sm {
        max-width: 100% !important;
    }
    .mt10{
        margin-top: 24rem!important;
      }
    .lazy-load-image-background.blur>img {
        width: 100%;
        display: block;
        margin: 0 auto;
    }
    .prod_ROW{
      margin: 0 !important;
    }
    .container, .container-md, .container-sm {
      max-width: 100%;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide {
        top: 80vh;
        overflow-x: scroll;
        display: flow-root;
        padding: 0px !important;
        white-space: nowrap;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide > form {
        display: inline-block;
        text-align: center;
        padding: 14px;
        text-decoration: none;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide > div,.scroller {
        display: none !important;
    }
    .pc_nav .btn_cityy button {
        padding: 5px !important;
        width: 100%;
        text-align: center;
    }
   .pc_nav .col-1.text-center.my-auto.cart_logg {
        width: 16% !important;
    } 
    svg.bi.bi-cart2 {
        stroke: #222;
        width: 18px;
    }
    .userDropdown svg {
        stroke: #222;
        width: 18px;
    }
    .col-6.col-md-4.pt-1.pb-1.btn_cityy.my-auto {
        width: 20% !important;
        font-size: 12px  !important;
        padding: 0px !important;
    }
    .col-4.d-none.d-sm-block.my-auto.search_pc.pt-1 {
        width: 38% !important;
    }
    button#cityField{
      font-size:12px !important;
    }
    .col-2.d-none.d-sm-block.text-center {
        width: 25% !important;
    }
    h5.prodItem_.mb-1 {
        height: 40px !important;
    }
    div#cartCount {
        top: 9px !important;
        right: -4px !important;
    }
    div#itemModal .modal-dialog {
        max-width: 100%;
    }
    
}
@media (min-width: 576px) {
    span.lazy-load-image-background.blur.lazy-load-image-loaded{
        mix-blend-mode: multiply;
    }
    .container, .container-md, .container-sm {
        max-width: 100% !important;
    }
    .mt10{
        margin-top: 30rem!important;
      }
    .prod_ROW{
      margin: 0 !important;
    }
    .container, .container-md, .container-sm {
      max-width: 100%;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide {
        top: 80vh;
        overflow-x: scroll;
        display: flow-root;
        padding: 0px !important;
        white-space: nowrap;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide > form {
        display: inline-block;
        text-align: center;
        padding: 14px;
        text-decoration: none;
    }
    ul.nav.nav-pills.nav-fill.list.firstSlide > div,.scroller {
        display: none !important;
    }
    .pc_nav .btn_cityy button {
        padding: 5px !important;
        width: 100%;
        text-align: center;
    }
   .pc_nav .col-1.text-center.my-auto.cart_logg {
        width: 16% !important;
    } 
    svg.bi.bi-cart2 {
        stroke: #222;
        width: 18px;
    }
    .userDropdown svg {
        stroke: #222;
        width: 18px;
    }
    .col-6.col-md-4.pt-1.pb-1.btn_cityy.my-auto {
        width: 20% !important;
        font-size: 12px  !important;
        padding: 0px !important;
    }
    .col-4.d-none.d-sm-block.my-auto.search_pc.pt-1 {
        width: 38% !important;
    }
    button#cityField{
      font-size:12px !important;
    }
    .col-2.d-none.d-sm-block.text-center {
        width: 25% !important;
    }
    h5.prodItem_.mb-1 {
        height: 40px !important;
    }
    div#cartCount {
        top: 9px !important;
        right: -4px !important;
    }
    div#itemModal .modal-dialog {
        max-width: 100%;
    }
    
    .col-3.sideNav_Act,.col-3.sideNav_OrderAdd {
        width: 100%;
    }
    .col-3.sideNav_Act > .row {
        position: relative !important;
        width: 100% !important;
    }.col-3.sideNav_Act {
    width: 100%;
    }
    .col-3.sideNav_OrderAdd > .row {
        position: relative !important;
        width:100% !important;
    }
    a.d-none.d-sm-inline-block > svg, button.d-none.d-sm-inline-block.btn.btn-transparent.p-0 > svg{
        stroke: #222;
    }
}


@media (min-width: 992px){
    .col-3.sideNav_Act,.col-3.sideNav_OrderAdd {
        width: 100%;
    }
    .col-3.sideNav_Act > .row {
        position: relative !important;
        width: 100% !important;
    }
    .col-3.sideNav_OrderAdd > .row {
        position: relative !important;
        width:100% !important;
    }
    .col-2.d-none.d-sm-block.text-center {
         width: 16.66667% !important; 
    }
    .col-4.d-none.d-sm-block.my-auto.search_pc.pt-1 {
        width: 33.33333% !important; 
    }
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333% !important;
    }
    .col-6.col-md-4.pt-1.pb-1.btn_cityy.my-auto {
        padding: 0px !important;
    }
    .pc_nav .btn_cityy button {
        padding: 5px !important;
        width: 59% !important;
        text-align: center;
    }
    .pc_nav .col-1.text-center.my-auto.cart_logg{
        width: 8.33333333%  !important;
    }
    .col-6.col-md-4.pt-1.pb-1.btn_cityy.my-auto{
        width: 33.33333333% !important;
    }
    button#cityField{
        font-size: 16px !important;
    }
    a.d-none.d-sm-inline-block > svg, button.d-none.d-sm-inline-block.btn.btn-transparent.p-0 > svg{
        // fill: #ffc107;
        width: auto;
        stroke: #ffc107 ;
    }
    svg.bi.bi-cart2 {
        fill: #ffc107;
        width: auto;
        stroke: unset !important;
    }
    .userDropdown svg {
        stroke: #ffc107 ;
        width: auto;
    }
    
    div#cartCount {
        top: 15px !important;
        right: 10px !important;
    }
    #CityModal{
        font-size: 16px !important;
    }
    button#setID{
        width: auto !important;
    }
    .cartdetails_ .card-body {
        width: 35% !important;
    }
    .modal-dialog {
        max-width: 500px !important;
    }
    #cartModal .modal.cart{
        width: 30% !important;
    }
}

@media (min-width: 1099px){
    .col-3.sideNav_Act, .col-3.sideNav_OrderAdd{
        width: 25% !important;
    }
    .col-3.sideNav_Act > .row{
        width: 25% !important;
        position:fixed !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 850px) {
    .mt10 {
        margin-top: 28rem !important;
    }
}
.circleIcon{
    border-radius: 100%;
    background-color: #ddd;
    margin: 0 auto;
    border: 3px solid #eee;
    width: 100%;
    height: 100%;
}
.circleIconAct{
    border: 3px solid rgb(36, 177, 75);
    border-radius: 50%;
    width: 100%;
    height: 100%;
}
.categActive{
    color: rgb(36, 177, 75);
    font-weight: 600;
}
.btn-light:hover, .btn-light:focus{
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important;
    box-shadow: unset !important;
}
.bgColorYellow{
    background-color: rgb(250, 204, 2);
}
#categoryNav .form-check-input{
    height: 2em;
}
ul.nav.nav-pills.nav-fill.list.firstSlide > form{
    margin-top: -2px;
}
label.form-check-label{
    line-height: 2.3;
}
.menuBtn{
    margin: auto;
    width: 36px;
    height: auto !important;
    max-height: 50px;
    display: flex;
    justify-content: center;
    img{
        height: auto !important;
        width: 25px;
    }
}
@media only screen and (max-width: 676) { 
  .col-6.iconLink.m-auto img {
    height: 45px;
    width: 45px;
  }
}