
/* reusable css */
.c-pointer{
    cursor: pointer;
}
.br-20{
    border-radius: 20px 20px 20px 20px;
}
.centeredItem{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.centeredText{
    text-align: center;
}
.leftText{
    text-align: start;
}
.marginLR{
    display: block;
    margin-left: 50px;
    margin-right: 50px;
}
.mt-10{
    margin-top: 10px;
}
.mt-20{
    margin-top: 20px;
}
.mt-50{
    margin-top: 50px;
}
.mt-100{
    margin-top: 100px;
}
.w-900{
    width: 900px;
}
.h-500{
    height: 500px;
}
.zgn-text-color{
    color: #facc09;
}
.mtop{
    margin-top: 100px;
}
.mtop p{
    word-spacing: 3px;
    line-break: strict;
}
.view-recipe{
    margin-left: 15px;
    max-width: 150px;
    font-size: 15px;
    left: 60%;
    text-decoration: none;
    padding:  10px 10px 10px 10px;
    border-radius: 5px 5px 5px 5px;
    border: 2.5px solid #facc09;
    color: #facc09;
}
.show-more-btn{
    position: relative;
}
.show-more{
    font-size: 16px;
    cursor: pointer;
    width: 100px;
    text-align: center;
    color: #23a547;
    display: block;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: auto;
    text-decoration: underline;
}
.print {
    text-align: center;
    max-width: 170px;
    padding: 5px;
    margin-left: 15px;
    border-radius: 5px;
    color: black;
    background-color: #facc09;
}
.print:hover{
    background-color: #23a547 ;
}

.share-on p, .featured-cooking-duration, .print{
    margin-top: auto;
    margin-bottom: auto;
}
.share-on{
    margin-top: 20px;
}
.view-recipe:hover{
    background-color: #facc09;
    color: black;
}
.nav-bar{
    position: absolute;
    width: 100%;
    height: 30px;
}
.nav-bar ul{
    float: right;
    margin-right: 10px;
    list-style: none;
    display: flex;
}
.nav-bar ul li{
    background-color: #facc09;
    margin-right: 5px;
    border-radius: 0px 0px 10px 10px;
}
.nav-bar ul li a{
    padding: 5px;
    font-size: 14px;
    text-decoration: none;
    color: black;
}

/* SlideShow/Banner CSS */
.carousel {
    padding: 0;
}

.carousel-indicators{
    margin-bottom: 0;
}
#recipeCarousel{
    padding-left: 0;
    padding-right: 0;
}
.carousel-control-prev, .carousel-control-next{
   width: 5%; 
}
 #recipeCarousel .carousel-inner{
    margin-top: 70px;
}
.carousel-inner{
    background-color: #777;
}
.carousel-item {
    background-color: #777;
    color: white;
    position: relative;
    z-index: -0;
}
.carousel-container {
    z-index: -0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: 50px;
    height: 600px;
}
.carousel-caption{
    padding-top: auto;
    padding-bottom: auto;
    top: 0;
    bottom: 0;
    width: 60%;
    height: 100%;
    margin-left: -100px;
    z-index: 2;
}
.carousel-desc{
    /* text-indent: 50px; */
}
.carousel-icons {
    display: block;
    bottom: 0;
    height: 100px;
    width: 100%;
}


/* carousel texts css */
.carousel-title, .carousel-desc, .carousel-header{
    color: #f5f5f8;
    text-align: initial;
}
.carousel-header{
    color: #000000;
    font-size: 20px; 
    height: 30px;
    margin-top: 50px;
}
.carousel-title{
    color: #facc09;
    height: 50px;
    font-size: 40px;
    margin-top: 65px;
}
.carousel-desc{
    height: 200px;
}
.carousel-overlay{
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
}


/* banner css */
.icons{
    margin-top: 20px;
}
.banner-icons svg, .carousel-icons svg{
    color: #facc09;
    margin-right: 30px;
    height: 40px;
    width: 40px;
}
.banner-icons svg:hover, .carousel-icons svg:hover{
    color: #23a547;
}
.banner-icons .share-on,.carousel-icons .share-on{
    text-align: left;
    height: 30px;
    color: #facc09;
}


/* Recommended CSS */
.recommended{
    padding-left: 100px;
    padding-right: 100px;
}
.recommended .product{
    margin-top: 50px;
}
.recommended ol, .recommended ul{
    font-size: 15px;
}


/* lists css */
.lists{
    margin-top: 20px;
}


/* productDescription css */
.productDescription{
    text-align: center;
}
.productDescription .recipeName p{
    font-size: 20px;
}
.prodImage{
    width: 900px;
    height: 500px;
}
.recipeName{
    margin-top: 100px;
    text-align: left;
}
.recipeName span ul{
    text-decoration: none;
    list-style: none;
    text-align: left;
    font-size: 20px;
    background-color: #f5f5f8;
    width: 450px;
    padding: 20px;
    border-radius: 0px 50px 50px 0px;
}
.recipeName p{
    /* text-indent: 5rem; */
}
.recipe-details{
    display: flex; 
    margin: 0;
}


/* ingredient css */
.ingredients{
    width: 35%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: -4px 4px 3px -5px darkgrey;
}
.ingredients p{
    width: 100%;
    word-wrap: break-word;
    padding-left: 20px;
    font-size: 12px;
    font-weight: 100;
}
.ingredients ul{
    list-style: none;
    width: 92%;
    padding-left: 20px;
    text-align: start;
}


/* procedure css */
.procedure{
    border-radius: 5px;
    width: 35%;
    margin-left: 10px;
    background-color: white;
    padding: 10px;
    box-shadow: -4px 4px 3px -5px darkgrey;
}
.procedure p{
    width: 100%;
    word-wrap: break-word;
    padding-left: 20px;
    font-size: 12px;
    font-weight: 100;
}
.procedure ol{
    width: 92%;
    font-size: 20px;
    margin-left: 30px;
    padding-left: 20px;
    text-align: start;
}


/* recipe description video tutorial css */
.tutorial{
    width: 29%;
    margin-top: auto;
    margin-bottom: auto;
}
.tutorial video{
    max-width: 450px;
    margin-left: 5%;
    margin-top:13%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}


.recipe-description p{
    font-size: 25px;
}
.recipe-description h1{
    font-size: 50px;
}


/* featured banner css */
.featured-banner{
    margin-top: 70px;
    position: relative;
    max-width: 100%;
    height: auto;
}

.featured-description{
    padding-left: 7%;
    padding-top: 8%;
    position: absolute;
    height: 100%;
    width: 70%;
    z-index: 2;
}
.featured-header{
    color: white;
}
.featured-title{
    color: #facc09;
    font-size: 50px;
}
.featured-desc{
    color: white;
    /* text-indent: 50px; */
}
.featured-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;
    background-color:black;
    opacity: 0.5;
}


/* collection css */
.collection{
    margin-top: 50px;
    background-color: white;
    padding: 25px 20px 25px 20px;
    box-shadow: -4px 4px 3px -5px darkgrey;
    border-radius: 5px 5px 5px 5px;
}
.collection-image-grid{
    margin-top: 20px;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill,minmax(220px, 1fr));
}
.collection-overlay-wrapper{
    position: relative;
}
.collection-overlay{
    border-radius: 5px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    background-color: #000000;
    opacity: 0.1;
}
.collection-image{
    border-radius: 5px;
    width: 100%;
    box-shadow: -0.5px 0.5px 4px -1.5px grey;
}
.collection-sliding-desc{
    margin-top: -45px;
    position: relative;
    background-color: white;
    border-radius:  0px 0px 5px 5px;
    overflow: hidden;
    transition: .2s ease-in-out;
    height: 45px;
}
.collection-text{
    padding: 10px 20px 0px 20px;
    color: #23a547;
    bottom: 0;
    text-align: center;
    font-weight: 50;
    font-size: 17px;
    z-index: 2;
}
.collection-desc{
    font-size: 13px;
    margin-top: -10px;
    margin-bottom: 5px;
    height: 55px;
    width: 100%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.collection-text-btn{
    width: 50%;
    margin-top: 5px;
    height: 40px;
    font-size: 15px;
}
.collection-icons{
    display: flex;
}
.collection-content:hover .collection-overlay{
    transition: .3s ease-in-out;
    opacity: 0.7;
}
.collection-content:hover .collection-image{
    transition: 0.3s ease-in-out;
}
.collection-content:hover .collection-sliding-desc{
    height: 159px;
    margin-top: -159px;
    text-overflow:clip;
    word-wrap: break-word;
    transition: .4s ease-in-out;
}
.collection-content:hover .collection-text-btn{
    opacity: 1;
}
.icon-flex{
    display: flex;
}


/* sub collection css */
.sub-collection-image-grid{
    margin-left: auto;
    margin-right: auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill,minmax(220px, 1fr));
  
}

/* suggested flex for scroll right  */
.suggested-flex{
    overflow-x: auto;
    margin-left: 15px;
    height: 270px;
}
.suggested-flex-content{
    margin-right: 2%;
    max-width: 250px;
}
.suggested-collection-image{
    height: 100%;
    width: 250px;
}

/* kitchen tips css */
.collection-kt-wrapper{
    box-shadow: -0.5px 0.5px 4px -1.5px grey;
}
.kt-video, .kt-desc{
    margin-left: auto;
    margin-right: auto;
    width: 95%;
}
.kt-video video{
    max-height: 500px;
}
.kt-desc h3{
    font-weight: 100;
}
.kt-desc p{
    font-size: 14px;
    text-indent: 30px;
}
.kitchen-tips-recommend ul{
    list-style: none;
    margin: 0;
    padding-left: 0;
}
.other-kt-height{
    max-height: 600px;
    overflow-y: auto;
}
.other-kt-li{
    padding: 10px;
    box-shadow: -4px 4px 3px -5px darkgrey;
    margin-bottom: 5px;
}.other-kt-li:hover{
    background-color: rgb(216, 215, 215);
}
.other-kt{
    height: 120px;
    cursor: pointer;
}
.other-kt-vid{
    height: 120px;
}
.other-kt-vid video{
    padding: 0;
}
.other-kt-desc{
    padding: 10px 15px 15px 15px;
    height: inherit;
    overflow: hidden;
}
.other-kt-desc span{
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
}
.other-kt-desc p{
    margin: 0;
    padding: 0px 15px 0px 15px;
    height: 70px;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 12px;
}



/* Mini Laptop CSS */
@media (max-width: 1024px) {
    /* featured recipe css */
    .featured-description{
        padding-left: 4%;
        padding-top: 5%;
        font-size: 15px;
        margin-left: 20px;
    }
    .featured-title{
        font-size: 40px !important;
    }
    .carousel-header{
        margin-top: 0;
        font-size: 15px !important;
    }
    .carousel-title{
        height: 35px;
    }
    .carousel-caption{
        margin-left: -50px;
    }
    .carousel-desc-content h2{
        font-size: 11px;
    }
    .carousel-desc-content h1{
        font-size: 30px;
    }
    .carousel-desc{
        height: 90px;
        font-size: 15px;
    }
    .view-recipe{
        padding: 5px;
    }
    .share-on p, .featured-cooking-duration, .print{
        font-size: 15px;
        margin-top: auto;
        margin-bottom: auto;
    }
    .banner-icons svg, .carousel-icons svg{
        height: 30px;
        width: 30px;
    }
    .ingredients{
        width: 49%;
        margin-left: auto;
        margin-right: auto;
    }
    .procedure{
        width: 49%;
        margin-left: auto;
        margin-right: auto;
    }
    .tutorial{
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .tutorial video {
        margin-top: 10%;
    }
    .kitchen-tips-recommend, .kitchen-tips-video{
        width: 100%;
    }
    .collection-image-grid, .sub-collection-image-grid{ 
        grid-template-columns: repeat(auto-fill,minmax(190px, 1fr));
    }
}


/* Tablet CSS */
@media (max-width: 768px) {
    .featured-description{
        padding-left: 10%;
        padding-top: 5%;
        font-size: 10px;
    }
    .featured-header, .carousel-header h2{
        font-size: 15px;
        margin-top: auto;
        margin-bottom: auto;
    }
    .featured-title, .carousel-title{
        font-size: 30px;
    }
    .featured-desc{
        margin-bottom: 10px;
    }
    .featured-banner{
        margin-top: 70px;
    }
    .icons{
        margin-top: 0;
    }
    .banner-icons svg, .carousel-icons svg{
        height: 20px;
        width: 20px;
    }
    .banner-icons .share-on,.carousel-icons .share-on{
        text-align: left;
        height: 30px;
    }
    .carousel-control-prev, .carousel-control-next, .carousel-inner{
        margin-top: 70px;
    }
    .collection-image-grid, .sub-collection-image-grid{ 
        grid-template-columns: repeat(auto-fill,minmax(170px, 1fr));
    }
    .carousel-desc-content h2{
        font-size: 20px;
    }
    .carousel-desc-content h1{
        height: 30px;
        margin-top: 0;
    }
    .carousel-header{
        margin-top: 0;
    }
    .carousel-title{
        height: 35px;
    }
    .carousel-caption{
        margin-left: -50px;
    }
    .carousel-desc{
        height: 80px;
        padding-bottom: 0;
        margin-bottom: 0;
        font-size: 12px;
    }
    .view-recipe{
        font-size: 9px;
        padding: 5px;
    }
    .carousel-description{
        height: auto;
    }
    .collection-text{
        margin-top: 8px;
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .collection-text-btn{
        height: 30px;
        font-size: 10px;
    }
    .header2{
        display: none;
    }
    .share-on p, .featured-cooking-duration, .print{
        font-size: 12px;
        margin-top: auto;
        margin-bottom: auto;
    }
    .ingredients{
        width: 100%;
        margin-bottom: 20px;
    }
    .procedure{
        width: 100%;
    }
}

/* large size phone */
@media (max-width: 426px) {
    .featured-description{
        margin-left: 15px;
        width: 85%;
        padding-left: 6%;
        padding-top: 18%;
        font-size: 12px;
    }
    .featured-desc{
        padding-bottom: 0;
        margin-bottom: 0;
        min-height: 100px;
    }
    .featured-title{
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .featured-header{
        font-size: 16px;
    }
    .featured-cooking-duration{
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .featured-banner{
        margin-top: 50px;
    }
    .carousel-control-prev, .carousel-control-next, .carousel-inner{
        margin-top: 50px;
    }
    .carousel-caption{
        margin-left: -20px;
        margin-top: -10px;
        width: 85%;
    }
    .carousel-icons{
        height: auto;
    }
    .carousel-icons-icons{
        margin-top: -10px;
    }
    .carousel-desc-content h2{
        font-size: 15px;
        margin-top: -15px;
    }
    .carousel-desc-content h1{
        font-size: 30px !important;
        margin-top: 50px;
    }
    .carousel-desc{
        font-size: 12px;
        min-height: 150px;
    }
    .carousel-description .share-on p{
        height: 30px;
        font-size: 12px;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .carousel-icons svg{
        margin-right: 15px;
    }
    .print{
        width: 100px;
    }
    .featured-cooking-duration, .print, .share-on p{
        font-size: 12px;
    }
    .banner-icons svg, .carousel-icons svg{
        height: 25px;
        width: 25px;
    }
    .banner-icons .share-on,.carousel-icons{
        text-align: left;
        height: 30px;
    }
    .banner-icons .icons{ 
        margin-top: -10px;
    }
    .collection-text{
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 5px 20px 0px 20px;
    }
    .collection-content:hover .collection-sliding-desc{
        height: 150px;
        margin-top: -150px;
    }
    .collection-image-grid, .sub-collection-image-grid{ 
        grid-template-columns: repeat(auto-fill,minmax(120px, 1fr));
    }
    .collection-margin, .floating-recipe-margin, .kitchen-component-margin{
        margin-left: 0;
        margin-right: 0;
    }
    .sub-collection-text{
        font-size: 13px;
    }
    .sub-collection-desc{
        font-size: 10px;
    }
    .recipe-details-margin, .suggested-recipe-margin, .sub-collection-margin{
        margin-left: 0;
        margin-right: 0;
    }
    .tutorial{
        width: 100%;
    }
    .suggested-recipe-margin .collection{
        margin-left: auto;
        margin-right: auto;
    }.suggested-flex {
        height: 220px
    }
    .suggested-flex-content{
        margin-right: 2%;
        max-width: 200px;
    }
    .suggested-collection-image{
        width: 200px;
    }
    .collection{
        margin-top: 20px;
    }
    .collection-slideshow .share-on{
        height: auto;
    }
    .collection-slideshow .share-on p{
        margin-top: 10px;
        height: auto;
    }
    .collection-slideshow .view-recipe{
        margin-top: -10px;
    }
    .collection-kt-wrapper{
        max-width: 220px;
    }
    .collection-kt-wrapper .collection-sliding-desc{       
        margin-top: -44px;
    }
    .view-recipe{
        font-size: 12px;
        padding:  5px;
    }
    .other-kt-vid{
        width: 120px;
    }
    .other-kt-desc{
        width: 190px;
    }
    .kt-video, .kt-desc{
        width: 100%;
    }
    .kt-video video{
        padding: 0;
    }
    .collection-mobile-flex{
        display: flex;
        overflow: auto;
        margin-bottom: 10px;
    }
    .collection-mobile{
        border-radius: 4px;
        box-shadow: -0.5px 0.5px 4px -1.5px grey;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 200px;
        margin-bottom: 10px;
    }
    .collection-mobile img{
        border-radius: 5px;
    }
    .collection-mobile .collection-overlay{  
        box-shadow: -4px 4px 3px -5px darkgrey;}
    }
    .collection-m{
        padding-bottom: 10px;
    }
    .ingredients h2, .procedure h2, .collection h2{
        font-size: 22px;
    }

@media (max-width: 391px) {
    .featured-description {
        padding-top: 10%;
    }
    .carousel-desc-content h1 {
        font-size: 27px !important;
        margin-top: 30px;
    }
}

/* medium size phone */
@media (max-width: 376px) {
    .kitchen-tips-video-margin{
        margin-top: 20px;
        padding: 0;
    }
    .kitchen-tips-recommend-vids{
        margin-top: 20px;
        padding: 0;
    }
    .other-kt-vid{
        width: 100px;
    }
    .other-kt-desc{
        width: 180px;
    }
    .featured-description{
        width: 90%;
        margin-left: -1px;
    }
    .carousel-caption{
        /* margin-top: -15px; */
        width: 90%;
    }
    .carousel-title{
        font-size: 15px;
    }
    .share-on p, .featured-cooking-duration{
        font-size: 12px;
    }
    .icons{
        margin-top: -5px;
    }
    .share-on {
        margin-top: 5px;
    }
    /* .suggested-collection-image{
        width: 203px;
    }
    .suggested-flex{
        height: 230px;
    } */
    .suggested-recipe-margin .collection{
        margin-left: auto;
        margin-right: auto;
    }.suggested-flex {
        height: 220px
    }
    .suggested-collection-image{
        width: 200px;
    }
    .collection-slideshow .share-on p{
        margin-top: -5px;
        height: auto;
    }
    .collection-slideshow .collection-icons{
        margin-top: 0px;
    }
    
    .collection-slideshow .view-recipe{
        padding: 0;
    }
}
@media (max-width: 321px) {
    .featured-description{
        width: 100%;
    }
    .share-on p, .featured-cooking-duration{
        font-size: 7px;
    }
    .icons{
        margin-top: -13px;
    }
    .share-on {
        margin-top: 2px;
    }
}