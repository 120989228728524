//search
#srBody h2{
    display: none;
}
#srBody .container {
    padding: 0 !important;
    padding-top: 8px !important;
}
// #firstContent{
//     margin-top: -70px;
// }
button#sortby_field{
    width: 50%;
    background-color: rgb(255, 255, 255) !important;
    color: rgb(0, 0, 0);
    text-align: left; 
    border: 1px solid !important; 
    margin-left: 1%;
    border-radius: 8px;
}
.dropdown-menu.show {
    width: 75%;
}

.dropdown-toggle::after{
    position: absolute;
    right: 10%;
    top: 40%;
}

//modal for city login transition from top 

#CityModal .modal , #LoginModal .modal.login{
    display: block;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.8s ease;
    top: 0px;
}
#CityModal .modal-dialog{
    transition: all 0.8s ease;
    top: 20%;
}
#CityModal .showModal, .modal.login.showModal{
  top: -9000px !important;
}

#CityModal span.invalid,#CityModal span.valid {
    top: 18% !important; 
}

.body_class, .body_class_{
  height: 300px;
  overflow: hidden;
}
#LoginModal .modal.login .modal-dialog{
    top: 0%;
}
.modal.login li.nav-item {
    width: 50%;
    padding: 10px;
}
.modal.login li.nav-item button {
    width: 100%;
}
.modal.login .nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;    
    width: 80%;
    margin: 0 auto;
}
.modal.login .nav-tabs .nav-item.show .nav-link, .modal.login .nav-tabs .nav-link.active {
    background-color: #24b14b !important;
    color: #fff;
    border: #24b14b !important;
}
.modal.login li.nav-item button {
    color: #222;
}
.modal.login button {
    width: 100%;
    margin-top: 0% !important;
}.modal.login .mt-4 {
    margin-top: 1% !important;
}
.modal.login .form-group{
    margin-bottom: 5% !important;
}
#cartModal .modal.cart {
    @media only screen and (max-width: 400px) {
        width:100%;
        // left:0;
    }
    @media only screen and (min-width: 401px) {
        width:30%;
        left:unset;
        right:2rem;
        border-radius: 15px;
        top: 0;
        height:347px;
    }
    display: block;
    border:1px solid #d3d6d8;
    background: white;
    transition: all 0.8s ease;
    overflow: hidden;
    z-index: 150;
}
.modal.cart .modal-dialog{
    @media only screen and (max-width: 400px) {
        height:100%
    }
}
.modal.cart .modal-content{
    @media only screen and (max-width: 400px) {
        height:100%
    }
}
.modal.cart .onCart{
    @media only screen and (max-width: 400px) {
        height:100%
    }
}
.modal.cart .modal-header.pt-2{
    @media only screen and (max-width: 400px) {
        width: 100%;
        height:54px
    }
}
.modal.cart .modal-body{
    @media only screen and (max-width: 400px) {
        height:84% !important
    }
}
.modal.cart .modal-footer.p-2{
    @media only screen and (max-width: 400px) {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height:54px;
        z-index: 991;
    }
}
.modal.cart.showModal{
    @media only screen and (max-width: 400px) {
        left:2000px
    }
    @media only screen and (min-width: 401px) {
        top: -1000px !important;
    }
}
#cartModal .modal.cart .modal-dialog {
    top: 0%;
    margin:0% !important
}
.modal.cart .modal-body{
    z-index: 150;
    height:100%;
    overflow-y: auto;
}



