.validSelected {
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    min-height: 38px;
    border-color: #24b14b !important;
}

.address::-webkit-scrollbar {
    height: 10px;
    margin-top:10px;
    width: 16px;

  }
  
  /* Track */
.address::-webkit-scrollbar-track {
background: #f1f1f1;

}

/* Handle */
.address::-webkit-scrollbar-thumb {
background: #24b14b;
border-radius: 100px;
}

/* Handle on hover */
.address::-webkit-scrollbar-thumb:hover {
background: #24b14b;
}

.addressView {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }