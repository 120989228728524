//search
#srBody h2{
    display: none;
}
#srBody .container {
    padding: 0 !important;
    padding-top: 8px !important;
}



button#sortby_field{
    width: 50%;
    background-color: rgb(255, 255, 255) !important;
    color: rgb(0, 0, 0);
    text-align: left; 
    border: 1px solid !important; 
    margin-left: 1%;
    border-radius: 8px;
}
.dropdown-menu.show {
    width: 75%;
}

.dropdown-toggle::after{
    position: absolute;
    right: 10%;
    top: 40%;
}

//modal for city login transition from top 

#CityModal .modal , #LoginModal .modal.login{
    display: block;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.8s ease;
    top: 0px;
}
#CityModal .modal-dialog{
    transition: all 0.8s ease;
    top: 20%;
}
#CityModal .showModal, .modal.login.showModal{
  top: -9000px !important;
}

#CityModal span.invalid,#CityModal span.valid {
    top: 18% !important; 
}

.body_class, .body_class_{
  height: 300px;
  overflow: hidden;
}
#LoginModal .modal.login .modal-dialog{
    top: 0%;
}
.modal.login li.nav-item {
    width: 50%;
    padding: 10px;
}
.modal.login li.nav-item button {
    width: 100%;
}
.modal.login .nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;    
    width: 80%;
    margin: 0 auto;
}
.modal.login .nav-tabs .nav-item.show .nav-link, .modal.login .nav-tabs .nav-link.active {
    background-color: #24b14b !important;
    color: #fff;
    border: #24b14b !important;
}
.modal.login li.nav-item button {
    color: #222;
}
.modal.login button {
    width: 100%;
    margin-top: 0% !important;
}.modal.login .mt-4 {
    margin-top: 1% !important;
}
.modal.login .form-group{
    margin-bottom: 5% !important;
}
#cartModal .modal.cart {
    display: block;
    border:0px solid #d3d6d8;
    border-radius: 15px;
    background: transparent;
    transition: all 0.8s ease;
    width:30%;
    left:unset;
    right:2rem;
    top: 64px;
    height:347px;
    overflow: hidden;
    z-index: 150;
}
.modal.cart.showModal{
    top: -1000px !important;
}
#cartModal .modal.cart .modal-dialog {
    top: 0%;
    margin:0% !important
}
.modal.cart .modal-body{
    z-index: 150;
    height:252px;
    overflow-y: auto;
}

.premiumProds span.lazy-load-image-background.blur.lazy-load-image-loaded {
    height: 186px !important;
    width: 75% !important;
    mix-blend-mode: multiply;
    transform: scale(1.2);
}
.premiumPage.mt10 {
    margin-top: 30% !important;
    width: 85% !important;
}

.premiumProds .itempriceSale{
    font-size: 14px !important;
}

.cardPrem {
    background-color: #f5f5f8 !important;
  border: 4px solid #f5f5f8 !important;

}
.premiumProds .w-100.buttonsField {
    min-height: 35px;
    text-align: right;
}

.premiumProds span.lazy-load-image-background.blur.lazy-load-image-loaded {
    height: 286px !important;
    width: 260px !important;
}
.premiumProds .itemDetails{
    margin-bottom: 5% !important;
} 
.premiumProds .itempriceSale{
    font-size:17px !important;
}
@media only screen and (max-width: 676px) {
    .premiumProds .itemDetails{
        margin-bottom: 0% !important;
    } 
    .premiumProds span.lazy-load-image-background.blur.lazy-load-image-loaded {
        height: 286px !important;
        width: 200px !important;
        mix-blend-mode: multiply;
        transform: scale(1);
    }
    h5.prodItem_.mb-1 {
        font-size: 14px !important;
        font-weight: bold;
        height: 43px !important;
    }
    .product_list{
        padding: 0 !important;
    }
    .col-12.p-2.itemDetails.col-12.mt-4.pt-0.pb-0.p-3 {
        margin-top: 0.5rem !important;
        text-align: left !important;
        padding: 0 !important;
    }
    .premiumPage.mt10 {
        margin-top: 55% !important;
        width: 100% !important;
    }
}

@media only screen and (max-width: 640px) {
    #cartModal #cartShowed.modal.cart {
        width: 100% !important;
        height: 100% !important;
        right: 0px;
        border: 0px solid #d3d6d8 !important;
        border-radius: 0 !important;
        top: 0;
    }
    #mobCartEmpt{
        padding: 10px !important;
    }
    #cartModal .modal.cart .modal-body{
      height: 87vh !important;
    }
    #cartModal .modal.cart .modal-header{
      height: 6vh !important;
    }
    #cartModal .modal.cart.showModal {
        right: -1000px !important;
        top: 0 !important;
    }
  }
  @media only screen and (max-width: 400px) {
    .modal.cart .modal-body{
      height: 700px !important;
      padding-bottom: 3rem !important;
    }
  }

  
  @media only screen and (max-width: 389px) {
    .modal.cart .modal-body{
      height: 500px !important;
    }
  }